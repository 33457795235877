import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api';

export const getCustomers = createAsyncThunk(
    'getCustomers',
    async (params: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.get('/branches/', { params });
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getCustomerById = createAsyncThunk(
    'getCustomerById',
    async (id: number, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.get(`/branches/${id}/`);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const createCustomer = createAsyncThunk(
    'createCustomer',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.post(`/branches/${data.orc_id}/`, data);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
