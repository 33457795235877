import QmeterLogoShort from '@/components/common/logo/qmeterLogoShort';
import { PAGE_HOME_PATH } from '@/constants/path';
import { AppDispatch } from '@/store';
import { checkMe, login } from '@/store/auth/action';
import { LoginType } from '@/types/auth';
import { Button, Form, Input } from 'antd';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {loginLoading} = useSelector((state: any) => state.authReducer);

    const handleLogin = (values: LoginType) => {
        const { email, password } = values;
        if (email?.length > 0 && password?.length > 0) {
            dispatch(login(values)).then((data) => {
                navigate(PAGE_HOME_PATH);
                dispatch(checkMe({}));
            });
        }
    };
    return (
        <div className='loginPage'>
            <div className='formContainer'>
                <div className='logo'>
                    <QmeterLogoShort />
                </div>
                <Form onFinish={handleLogin} className='form'>
                    <Form.Item name='email' className='formItem'>
                        <Input
                            placeholder='E-mail'
                            className='loginInp'
                            prefix={<AiOutlineUser />}
                        />
                    </Form.Item>
                    <Form.Item name='password' className='formItem'>
                        <Input
                            placeholder='Password'
                            className='loginInp'
                            type='password'
                            prefix={<AiOutlineLock />}
                        />
                    </Form.Item>
                    <Form.Item className='formItem'>
                        <Button loading={loginLoading} htmlType='submit' className='submitBtn'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;
