import { Col, Row } from 'antd';
import { memo } from 'react';
import UpcomingTable from '../../Upcoming/Table';
// import LatestFeedbacksTable from '../LatestFeedbacksTable';
import FeedbackTable from "@/containers/Dashboard/Tables/latestFeedbackTable";

// @ts-ignore
const ThirdSection = ({ company = null }: any) => {
    return (
        <Row className='dashboardSecondSection'>
            <Col xs={12} className='col'>
                <div className='table_container_upcoming'>
                    <FeedbackTable company={company} />
                </div>
            </Col>
            <Col xs={12} className='col'>
                <div className='table_container_upcoming'>
                    <UpcomingTable />
                </div>
            </Col>
        </Row>
    );
};

export default memo(ThirdSection);
