export const feedbackBalance = [
    {
        title: 1000,
        color: '#f6685d'
    },
    {
        title: 5000,
        color: '#f7a35b'
    },
    {
        title: 10000,
        color: '#90ee7e'
    },
    {
        title: 50000,
        color: '#7cb5ec'
    },
    {
        title: 100000,
        color: '#7798bf'
    },
    
]