import { Col, Form, Select } from 'antd';
import CustomLabel from '../Label';

const CustomSelect = ({
    mode,
    options,
    label,
    required,
    placeholder,
    defaultValue,
    onChange,
    fieldName,
    colSize = 8,
    style
}: any) => {
    return (
        <Col xs={colSize} className='col'>
            <CustomLabel label={label} required={required} />
            <Form.Item name={fieldName} className='formItem'>
                <Select
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    options={options?.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })}
                    onChange={(e) => onChange(e, fieldName)}
                    mode={mode}
                    style={style}
                />
            </Form.Item>
        </Col>
    );
};

export default CustomSelect;
