import moment from "moment";

export const getDateRange = (type: string) => {
  const today = moment().format('YYYY-MM-DD');

  switch (type) {
    case 'today':
      return { first_date: today, last_date: today };
    case 'yesterday':
      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
      return { first_date: yesterday, last_date: yesterday };
    case 'this-week':
      const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
      const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
      return { first_date: startOfWeek, last_date: endOfWeek };
    case 'last-week':
      const startOfLastWeek = moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD');
      const endOfLastWeek = moment().endOf('week').subtract(7, 'days').format('YYYY-MM-DD');
      return { first_date: startOfLastWeek, last_date: endOfLastWeek };
    case 'this-month':
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      return { first_date: startOfMonth, last_date: endOfMonth };
    case 'this-year':
      const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
      const endOfYear = moment().endOf('year').format('YYYY-MM-DD');
      return { first_date: startOfYear, last_date: endOfYear };
    default:
      return { first_date: '', last_date: '' };
  }
};

export const predefinedDates = [
  {
    label: 'All Times',
    value: '',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'This Week',
    value: 'this-week',
  },
  {
    label: 'Last Week',
    value: 'last-week',
  },
  {
    label: 'This Month',
    value: 'this-month',
  },
  {
    label: 'This Year',
    value: 'this-year',
  },
];
