import CompanySelect from '@/components/common/select/CompanySelect';
import CountrySelect from '@/components/common/select/CountrySelect';
import DateRangeSelect from '@/components/common/select/DateRangeSelect';
import PredefinedSelect from '@/components/common/select/PredefinedSelect';
import { topDashMenu } from '@/resources/dashboard/topDashMenu';
import { AppDispatch, IRootStore } from '@/store';
import { setFilter } from '@/store/dashboard/dashboardSlice';
import { getCountriesAndCompanies } from '@/store/stable/action';
import { StableStateType } from '@/types/stable';
import { Button, Col, Row } from 'antd';
import { ReloadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAdvancedReportDataSearch } from '@/store/dashboard/dashboardSlice';

const TopDashboardSection = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {filter, advancedReportDataSearch} = useSelector((state: IRootStore) => state.dashboardReducer)
    const { companies, countries } = useSelector<IRootStore, StableStateType>(
        (store) => store.stableReducer,
    );

    const handleClearFilters = () => {
        dispatch(setFilter({
            country:[],
            company:null,
            customDate:null,
            predefinedDate: {
                label: null,
                dateRange: null
            }
        }));
    };

    const handleDataRefresh = () => {
        dispatch(setFilter({...filter, refresh: !filter.refresh}))
        dispatch(setAdvancedReportDataSearch({refresh: !advancedReportDataSearch.refresh}));
    };

    useEffect(() => {
        dispatch(getCountriesAndCompanies({}));
    }, []);
    return (
        <div className='layoutDashTopSection'>
            <Row className='row'>
                {topDashMenu.map((menuItem) => {
                    return (
                        <Col className='col' /* xs={5} */ key={menuItem.key}>
                            <div style={{ width: '100%'/* , padding: 8  */}}>
                                <div className='menuName'>{menuItem.label}</div>
                                {menuItem.key === 1 ? (
                                    <CountrySelect options={countries} />
                                ) : menuItem.key === 2 ? (
                                    <CompanySelect options={companies} />
                                ) : menuItem.key === 3 ? (
                                    <PredefinedSelect />
                                ) : (
                                    <DateRangeSelect />
                                )}
                            </div>
                            
                        </Col>
                    );
                })}
                <Col className='col' /* xs={5} */ style={{paddingTop: '29px', gap: 8}}> 
                    <Button /* style={{width: '30%'}} */ onClick={handleClearFilters} className='clearFiltersBtn'>Clear Filters</Button>
                    <Button /* style={{width: '30%'}}  */onClick={handleDataRefresh} className='refreshPageBtn'>Refresh</Button>
                </Col>
            </Row>
            {/* <div className='layoutDashTopSection-btns'>
                <Button onClick={handleClearFilters} className='clearFiltersBtn'><CloseCircleOutlined /></Button>
                <Button onClick={handleDataRefresh} className='refreshPageBtn'><ReloadOutlined /></Button>
            </div> */}
        </div>
    );
};

export default memo(TopDashboardSection);
