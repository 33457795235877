import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootStore } from '@/store';
import { toggleModal } from '@/store/modal/modalSlice';
import { UserStateType } from '@/types/user';
import { getUsers } from '@/store/user-management/action';
import { getCountriesAndCompanies, getStableForUser } from '@/store/stable/action';
import { getUserById } from '@/store/user-management/userManagementSlice';
import getTableColumns from './getTableColumns';
import UserManagementForm from './UserManagementForm';
import CommonPageHeader from '@/components/common/header/CommonPageHeader';
import CommonPageTable from '@/components/common/table/CommonPageTable';


const UserManagement = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { count, users, isGetLoading } = useSelector<IRootStore, UserStateType>(
        (store) => store.userManagementReducer,
    );
    const userManagementTableColumns = getTableColumns(editUserAction);

    const fetchStableData = () => {
        dispatch(getStableForUser({}));
        dispatch(getCountriesAndCompanies({}));
    };

    function editUserAction(id: number) {
        fetchStableData();
        dispatch(getUserById(id));
        dispatch(
            toggleModal({ text: 'Edit user details', type: 'user_management', action: 'edit' }),
        );
    }

    useEffect(() => {
        dispatch(getUsers({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <UserManagementForm />
            <CommonPageHeader
                header='Manage users'
                subText='You can manage your users from this section.'
                modalHead='Create new user'
                type='user_management'
                onClickAdd={fetchStableData}
            />
            <CommonPageTable
                data={users}
                columns={userManagementTableColumns}
                total={count}
                loading={isGetLoading}
                searchTab={false}
            />
        </div>
    );
};

export default UserManagement;
