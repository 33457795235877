import { ACCESS_TOKEN_KEY } from '@/constants/global';
import { login, checkMe, logout } from './action/index';
import { createSlice } from '@reduxjs/toolkit';
import { AuthStateType, AuthUserType } from '@/types/auth';

const initialState: AuthStateType = {
    user: {} as AuthUserType,
    loginLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(login.pending, (state: AuthStateType) => {
            state.loginLoading = true;
        });
        builder.addCase(login.fulfilled, (state: AuthStateType, { payload }: any) => {
            localStorage.setItem(ACCESS_TOKEN_KEY, payload.token);
            state.user = payload.user;
            state.loginLoading = false;
        });
        builder.addCase(login.rejected, (state: AuthStateType) => {
            state.loginLoading = false;
        });
        builder.addCase(checkMe.pending, (state: AuthStateType) => {
            state.user = {} as AuthUserType;
            state.loginLoading = true;
        });
        builder.addCase(checkMe.fulfilled, (state: AuthStateType, { payload }: any) => {
            state.user = payload;
            state.loginLoading = false;
        });
        builder.addCase(checkMe.rejected, (state: AuthStateType) => {
            window.location.href = '/login';
            state.user = {} as AuthUserType;
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            state.loginLoading = false;
        });

        builder.addCase(logout.fulfilled, (state: AuthStateType, { payload }: any) => {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            state.user = {} as AuthUserType;
        });
    },
});

export default authSlice.reducer;
