import { setFilter } from '@/store/dashboard/dashboardSlice';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';

const CountrySelect = ({ options }: any) => {

    
        const {filter} = useSelector((state: any) => state.dashboardReducer);

        const dispatch = useDispatch();

        const handleSelect = (value: string) => {
            // setSelectedCompanyId(value);
            dispatch(setFilter({...filter, country:value}));
            // Do something with the selected value
        }

    return (
        <Select
            onSelect={handleSelect}
            value={filter.country}
            showSearch
            allowClear
            onClear={() => dispatch(setFilter({
                country:[]
            }))}
            placeholder='Select by country'
            className='countrySelect'
            autoFocus = {false}
            options={options?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            })}  
        />
    );
};

export default CountrySelect;
