import { configureStore } from '@reduxjs/toolkit';
import localReducer from './modal/modalSlice';
import userManagementReducer from './user-management/userManagementSlice';
import saleManagementReducer from './sale/saleManagementSlice';
import customerReducer from './customer/customerSlice';
import authReducer from './auth/authSlice';
import stableReducer from './stable/stableSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import licensesManagementReducer from './licenses/licensesManagementSlice';
import logsManagementReducer from './logs/logsManagementSlice'
import aiManagementReducer from './ai/aiManagementSlice'

export const store = configureStore({
    reducer: {
        localReducer,
        stableReducer,
        userManagementReducer,
        saleManagementReducer,
        customerReducer,
        authReducer,
        dashboardReducer,
        licensesManagementReducer,
        logsManagementReducer,
        aiManagementReducer
    },
});

export type IRootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
