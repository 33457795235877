export const salesMockData = {
    total: 106,
    data: [
        {
            id: 160,
            user: <span>Lorem, ipsum.</span>,
            customer: 115,
            count: 1,
            date: '2019-07-05',
        },
        {
            id: 158,
            user: <span>Lorem, ipsum.</span>,
            customer: 115,
            count: 10,
            date: '2018-01-02',
        },
        {
            id: 157,
            user: <span>Lorem, ipsum.</span>,
            customer: 115,
            count: 1,
            date: '2017-12-27',
        },
        {
            id: 156,
            user: <span>Lorem, ipsum.</span>,
            customer: 81,
            count: 1,
            date: '2017-12-27',
        },
        {
            id: 155,
            user: <span>Lorem, ipsum.</span>,
            customer: 81,
            count: 1,
            date: '2017-12-27',
        },
        {
            id: 153,
            user: <span>Lorem, ipsum.</span>,
            customer: 83,
            count: 1,
            date: '2017-12-27',
        },
        {
            id: 152,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 150,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 149,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 148,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 147,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 146,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 144,
            user: <span>Lorem, ipsum.</span>,
            customer: 81,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 143,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 142,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 141,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 139,
            user: <span>Lorem, ipsum.</span>,
            customer: 114,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 138,
            user: <span>Lorem, ipsum.</span>,
            customer: 115,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 137,
            user: <span>Lorem, ipsum.</span>,
            customer: 115,
            count: 1,
            date: '2017-12-26',
        },
        {
            id: 136,
            user: <span>Lorem, ipsum.</span>,
            customer: 82,
            count: 1,
            date: '2017-12-26',
        },
    ],
};
