import { useState } from 'react';
import { Row, Button, Input, Col, Form } from "antd"
import { feedbackBalance } from '@/resources/licenses/feedbackBalance';
import { useSelector } from "react-redux";
import { IRootStore } from "@/store";
import { ILicenseState } from "@/types/license";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { setLicenceModalDetails, setUpdFilters } from '@/store/licenses/licensesManagementSlice';
import CustomLabel from '@/components/common/forms/Label';

export const WebBalance = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [webBalance, setWebBalance] = useState<number>(0);
    
    const {licenceModalDetails, updFilter} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);
    
    const handleButtonBalance = (value: number) => {
        dispatch(setUpdFilters({id: licenceModalDetails.id, feedback_balance: webBalance + value}))
        setWebBalance(webBalance + value)
      };

      const handleInputChange = (val: any) => {
        dispatch(setUpdFilters({...updFilter, id: licenceModalDetails.id, feedback_balance: val}))
        setWebBalance(val)
      };
    

  return (
    <Row className="row">
            <Row style={{width: '100%', alignItems: 'center'}}>
                <Col className='col'>
                    <CustomLabel label="Web feedback balance" required={true} />
                    <Form.Item className='formItem'>
                        <Input
                            required={true}
                            placeholder="Enter web balance"
                            type="number"
                            style={{borderRadius: 0, width: 200}}
                            value={webBalance}
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
                {feedbackBalance.map((item, index) => (
                    <Button
                        key={index}
                        style={{backgroundColor: item.color, color: '#fff', marginTop: 20, marginLeft: 5, fontWeight: 500, border: 'none' }}
                        onClick={() => handleButtonBalance(item.title)}
                    >
                        +{item.title}
                    </Button>
                ))}
            </Row>
    </Row>
  )
}
