import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store';
import { setUserPassword } from '@/store/user-management/userManagementSlice';
import Input from '@/components/common/forms/Input';

export const UserChangePasswordForm = () => {
    const dispatch = useDispatch<AppDispatch>();

    const handleChangeInput = (value: string, key: string) => {
        dispatch(
            setUserPassword({
                field: key,
                value,
            }),
        );
    };
    return (
        <Row className='row'>
            <Input
                label='Password'
                placeholder='Enter password'
                onChange={handleChangeInput}
                type='password'
                fieldName='password'
                suffix='18'
            />
            <Input
                label='Confirm Password'
                required={true}
                placeholder='Enter confirm password'
                onChange={handleChangeInput}
                type='password'
                fieldName='password2'
                suffix='18'
                rules={[
                    ({ getFieldValue }: any) => ({
                        validator(_: any, value: any) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords must match.'));
                        },
                    }),
                ]}
            />
        </Row>
    );
};
