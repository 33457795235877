import { memo, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@/store';
import { getFeedBackLines } from '@/store/dashboard/action';

const buttonDisabled = {
  type: "year",
  count: 100,
};

const AreaChart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { feedbackLines, filter } = useSelector((state: any) => state.dashboardReducer);

  const max = Math.max(...feedbackLines.data || []);
  const buttons = [
    {
      type: "all",
      text: "All",
      title: "All",
    },
    {
      type: "ytd",
      count: 100,
      text: "Monthly",
      title: "Monthly",
    },
    {
      type: "day",
      count: new Date().getDate() - 1,
      text: "This Month",
      title: "This Month",
    },
  ];
  const [options, setOptions] = useState({
    chart: {
      height: 400,
      spacingTop: 20,
      spacingLeft: 20,
      spacingRight: 20,
      spacingBottom: 30,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.1)",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "<p style='margin-top: 6px; margin-bottom: 0'>SLI Chart</p>",
      useHTML: true,
      style: {
        fontFamily: "font-medium",
        color: "#555",
        fontSize: "14px",
        margin: "12px 0",
      },
    },
    subtitle: {
      text: "Click small/large buttons or change window size to test responsiveness",
    },
    rangeSelector: {
      enabled: true,
      labelStyle: {
        display: "none",
      },
      buttonTheme: {
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
      },
      buttons: buttons,
      buttonPosition: {
        x: -36,
        y: - 8,
      },
    },
    plotOptions: {
      series: {
        animation: false,
        color: "rgb(119, 152, 191)",
        negativeColor: "rgb(246, 104, 93)",
      },
      rangeSelector: {
        animation: false,
      },
    },
    series: [
      {
        name: "SLI",
        showInLegend: false,
        data: [] as Array<[number, number]>,
        type: "area",
        threshold: 0,
        tooltip: {
          valueDecimals: 2,
        },
        dataLabels: {
          enabled: false,
        },
      },
    ],
    
    yAxis: {
      min: -100,
      max: 100,
      title: {
        text: "SLI Score",
      },
    },
    xAxis: {
      type: "datetime",
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            chart: {
              height: 300,
            },
            subtitle: {
              text: null,
            },
            navigator: {
              enabled: false,
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    dispatch(
      getFeedBackLines({
        company_id: filter.company,
        country: filter.country,
        first_date:
          filter.customDate?.[0] || filter.predefinedDate.dateRange?.first_date,
        last_date:
          filter.customDate?.[1] || filter.predefinedDate.dateRange?.last_date,
      })
    );
  }, [filter]);

  useEffect(() => {
    const newData = Object.keys(feedbackLines)?.map((k, i) => feedbackLines[k]);
    const newButttons: { type: string; count?: number; text: string; title: string }[] = [];
    const currentDate = new Date();
    buttons.forEach((button, i) => {
      const lastFeedback =
        newData.length > 0 ? new Date(newData[newData.length - 1][0]) : null;
      if (
        newData.length === 0 ||
        (i === 1 &&
          new Date(newData[0][0]).getFullYear() ===
            currentDate.getFullYear()) ||
        (i === 2 && lastFeedback && lastFeedback.getMonth() !== currentDate.getMonth())
      ) {
        newButttons.push({ ...button, ...buttonDisabled });
      } else if (i === 2) {
        newButttons.push({
          ...button,
          count: lastFeedback!.getDate() - 1,
        });
      } else {
        newButttons.push(button);
      }
    });

    setOptions({
      ...options,
      series: [
        {
          ...options.series[0],
          data: newData as Array<[number, number]>,
        },
      ],
      rangeSelector: { enabled: true, labelStyle: { display: "none" }, buttonTheme: { width: "100%", paddingLeft: 10, paddingRight: 10 }, buttons: newButttons, buttonPosition: { x: -36, y: -8 } },
    });
  }, [feedbackLines]);

  return (
    <div className="layoutDashSliChart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default memo(AreaChart);
