import { useState } from "react";
import { Row, Radio, Col, Collapse, Divider } from "antd"
import { AddNewLicense } from "./AddNewLicense";
import { ExtendLicense } from "./ExtendLicense";

const { Panel } = Collapse;

export const LicenseUpgrade = () => {

    const [activePanel, setActivePanel] = useState('');

    const handlePanelChange = (key: any) => {
      setActivePanel(key[1]);
    };
    return (
        <Row className="row">
             <Collapse 
                activeKey={activePanel || ''} 
                onChange={handlePanelChange} 
                style={{width: '100%', backgroundColor: '#fff'}}

             >
                <Panel header="Add new device license" key="1">
                    <AddNewLicense type='add' />
                </Panel>
                <Panel header="Extend Current License" key="2" >
                    <ExtendLicense type='extend' />
                </Panel>
                <Panel header="Add & Extend License" key="3">
                        <AddNewLicense type="add-extend" />
                        <Divider />
                        <ExtendLicense type="add-extend" />
                </Panel>
            </Collapse>
        </Row>
    )
}