import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

export const getLicenses = createAsyncThunk(
    'getLicenses',
    async (
      { page_size, page_number, is_active, name, field_name, type}: {
        page_size: number,
        page_number: number,
        is_active: null,
        name: null,
        field_name: null,
        type: null,
      },
      { rejectWithValue }
    ) => {
      try {
        const requestBody: any = {
            page_size,
            page_number
          };
    
          const reportFilter: any = [];
    
          if (is_active !== null) {
            reportFilter.push({is_active: is_active});
          }
          if(name !== "" && name !== null) {
                reportFilter.push({name: name})
            }
    
          if (Object.keys(reportFilter).length > 0) {
            requestBody.report_filter = reportFilter;
          }

          if (field_name !== null && type !== null && field_name !== undefined && type !== undefined) {
            requestBody.sort = {
              field_name,
              type
            };
          }
    
        const result = await axiosInstance.post('/license/', requestBody);
        return result.data;
      } catch (err: any) {
        return rejectWithValue(err);
      }
    }
  );
  

export const updateLicense = createAsyncThunk('updateLicense', async ({
    is_active,
    id,
    feedback_balance,
    period,
    sms_balance,
    devicelicense_account_count,
    upgrade_type
    }:{
        is_active: null,
        id: null,
        feedback_balance: null,
        period: null,
        sms_balance: null,
        devicelicense_account_count: null,
        upgrade_type: null,
    }, { rejectWithValue }) => {
    try {
        const requestBody: any = {
            is_active,
            id,
            feedback_balance,
            period,
            sms_balance,
            devicelicense_account_count,
            upgrade_type
        };
        const updatedBody = Object.keys(requestBody).reduce((acc: any, key: string) => {
            if (requestBody[key] !== null) {
              acc[key] = requestBody[key];
            }
            return acc;
          }, {});
          
        const result = await axiosInstance.post(`/updatelicense/ `, updatedBody);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
}
);