import { IRootStore } from '@/store';
import { ModalType } from '@/store/modal/modalSlice';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

const CustomModal = ({ children, onCancelModal, width = 768 }: any) => {
    const { open, modalHeader, action } = useSelector<IRootStore, ModalType>((state) => state.localReducer);
    return (
        <Modal 
            footer={false} 
            width={action === 'delete' ? 500 : width} 
            className='modal' 
            open={open} 
            onCancel={onCancelModal} 
            destroyOnClose
        >
            <div className='modalHeader'>
                <div className='headerContent'>{modalHeader}</div>
            </div>
            {children}
        </Modal>
    );
};

export default CustomModal;
