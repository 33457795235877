import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api';

export const getLatestFeedback = createAsyncThunk(
    'getLatestFeedback',
    async ({page, page_size, company, country, first_date, last_date }: {page: number, page_size: number, company: any, country: any, first_date: any, last_date: any }, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.get('/latest-feedback/', { params: {page, page_size, company, country, first_date, last_date } });
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getRatePercent = createAsyncThunk(
  'getRatePercent',
  async ({ company_id, country, first_date, last_date }: { company_id: number | null, country: string, first_date: any, last_date: any }, { rejectWithValue }) => {
    try {
      const data: any = {};

      const properties = [
        { key: 'company_id', value: company_id },
        { key: 'country', value: country, condition: country !== null && country.length > 0 },
        { key: 'first_date', value: first_date },
        { key: 'last_date', value: last_date },
      ];

      properties.forEach(property => {
        if (property.value !== null && (!property.condition || property.condition)) {
          data[property.key] = property.value;
        }
      });

      const result = await axiosInstance.post('/rates/', data);
      return result.data;

    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);



export const getFeedBackLines = createAsyncThunk(
    'getFeedBackLines',
    async ({ company_id, country, first_date, last_date }: { company_id: number, country: string, first_date: any, last_date: any }, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.post('/feedbacks-chart/', { company_id, country, first_date, last_date });
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getExpireDate = createAsyncThunk(
  'getExpireDate',
  async (params: any, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get('/expire-date/', { params });
      return result.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);


export const getCountryById = createAsyncThunk(
    'getCountryById',
    async (id: number, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.get(`company-data/${id}/`);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getOverallData = createAsyncThunk(
    'getOverallData',
    async ({ company_id, country, first_date, last_date }: { company_id: any, country: string, first_date: any, last_date: any }, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.get('/overall-data/', { params: { company_id, country, first_date, last_date } });
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getAdvancedReport = createAsyncThunk(
    'getAdvancedReport',
    async (
      {
        page_size,
        page_number,
        name,
        country,
        first_name,
        last_name,
        email,
        phone_number,
        devicelicense_account_count,
        branch_count,
        field_name,
        type
      }: {
        page_size: any,
        page_number: any,
        name?: string,
        country?: string,
        first_name?: string,
        last_name?: string,
        email?: string,
        phone_number?: string,
        devicelicense_account_count?: string,
        branch_count?: string,
        field_name?: string,
        type?: string
      },
      { rejectWithValue }
    ) => {
      try {
        const filter = {
          name,
          country,
          first_name,
          last_name,
          email,
          phone_number,
          devicelicense_account_count,
          branch_count
        };
  
        const sort = {
          field_name,
          type
        };
  
        const reportFilter: Partial<typeof filter> = {};
  
        for (const key in filter) {
          if (filter.hasOwnProperty(key) && filter[key as keyof typeof filter] !== undefined && filter[key as keyof typeof filter] !== null) {
            reportFilter[key as keyof typeof filter] = filter[key as keyof typeof filter] as string;
          }
        }
  
        const requestBody: any = {
          page_size,
          page_number,
          ...(Object.keys(reportFilter).length > 0 && { report_filter: reportFilter }),
        };
  
        if (field_name !== null && type !== null) {
          requestBody.sort = sort;
        }
  
        const result = await axiosInstance.post('/report/', requestBody);
        return result.data;
      } catch (err: any) {
        return rejectWithValue(err);
      }
    },
  );
  
  
  
