import { LatestFeedbackType } from '@/types/dashboard';

import {UserOutlined, GlobalOutlined, CommentOutlined, MobileOutlined} from "@ant-design/icons";

import { ExpireDateType } from '@/types/dashboard';

export function formatTimeAgo(dateString: any) {
  const date = new Date(dateString);
  const now = new Date();

  if(dateString === undefined) return '';

  // @ts-ignore
    const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) {
    return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }

  const hours = Math.floor(minutes / 60);

  if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  }

  const days = Math.floor(hours / 24);

  if (days < 30) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  }

  const months = Math.floor(days / 30);

  if (months < 12) {
    return `${months} month${months === 1 ? '' : 's'} ago`;
  }

  const years = Math.floor(months / 12);

  return `${years} year${years === 1 ? '' : 's'} ago`;
}

const latestFeedbackDataColumns = (getCompany: (id: number) => void) => {
    return [
                {
            title: <span className='menuName'>Companies </span>,
            render: ({ name, id }: ExpireDateType) => (
                <span className='menuValue companyLink'>
                    {name?.length > 30 ? name.slice(0, 30) + "..." : name}
                </span>
            ),

        },
        {
            title: <span className='menuName'>Countries</span>,

            render: ({ country }: LatestFeedbackType) => (
                <span className='menuValueDarkGray' title={country}>
                    {country.length > 20 ? country.slice(0, 20) + "..." : country}
                </span>
            ),
        },

        {
            title: <span className='menuName'>Time</span>,
            render: ({ time }: LatestFeedbackType) => <span className='menuValueDarkGray'>{formatTimeAgo(time)}</span>,
        },
        {
            title: <span className='menuName'></span>,
            render: ({ icons }: LatestFeedbackType) => {
              let icon_s = [];
              // @ts-ignore
                if (icons?.includes('customer')) {
                icon_s.push(<UserOutlined style={{ fontSize: '16px', marginRight: '5px'}}/>);
              }
              // @ts-ignore
                if (icons?.includes('comment')) {
                icon_s.push(<CommentOutlined style={{ fontSize: '16px', marginRight: '5px'}}/>);
              }
              // @ts-ignore
                if (icons?.includes('web_channel')) {
                icon_s.push(<GlobalOutlined style={{ fontSize: '16px', marginRight: '5px'}}/>);
              }
                // @ts-ignore
                if (icons?.includes('device_channel')) {
                icon_s.push(<MobileOutlined style={{ fontSize: '16px'}}/>);
              }
              return (
                <span className="menuValue">
                  {icon_s}
                </span>
              );
            },
        }
];
};

export default latestFeedbackDataColumns;