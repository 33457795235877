import {
    getCountriesAndCompanies,
    getStableForUser,
    getStableForCustomer,
    getStableForSales,
} from './action/index';
import { createSlice } from '@reduxjs/toolkit';
import { StableStateType } from '@/types/stable';

const initialState: StableStateType = {
    countries: [],
    companies: [],
    branches: [],
    jobs: [],
    roles: [],
    teams: [],
    statuses: [],
    users: [],
    customers: [],
    loading: false,
};

export const stableSlice = createSlice({
    name: 'stable',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get Countries And Companies Data action
        builder.addCase(getCountriesAndCompanies.pending, (state: StableStateType) => {
            state.loading = true;
        });
        builder.addCase(
            getCountriesAndCompanies.fulfilled,
            (state: StableStateType, { payload }) => {
                const { countries, companies } = payload;
                const formatedCountries = Object.entries(countries).map((country) => {
                    const [name] = country;
                    return {
                        name,
                        id: name,
                    };
                });
                state.countries = formatedCountries;
                state.companies = companies;
                state.loading = false;
            },
        );
        builder.addCase(
            getCountriesAndCompanies.rejected,
            (state: StableStateType, action: any) => {
                state.loading = false;
            },
        );

        // Get Stable Data For User action
        builder.addCase(getStableForUser.pending, (state: StableStateType) => {
            state.loading = true;
        });
        builder.addCase(getStableForUser.fulfilled, (state: StableStateType, { payload }) => {
            state.branches = payload.branch;
            state.jobs = payload.jobs;
            state.roles = payload.roles;
            state.teams = payload.team;
            state.loading = false;
        });
        builder.addCase(getStableForUser.rejected, (state: StableStateType, action: any) => {
            state.loading = false;
        });

        // Get Stable Data For Customer action
        builder.addCase(getStableForCustomer.pending, (state: StableStateType) => {
            state.loading = true;
        });
        builder.addCase(getStableForCustomer.fulfilled, (state: StableStateType, { payload }) => {
            state.loading = false;
        });
        builder.addCase(getStableForCustomer.rejected, (state: StableStateType, action: any) => {
            state.loading = false;
        });

        // Get Stable For Sales Date action
        builder.addCase(getStableForSales.pending, (state: StableStateType) => {
            state.loading = true;
        });
        builder.addCase(getStableForSales.fulfilled, (state: StableStateType, { payload }) => {
            state.loading = false;
        });
        builder.addCase(getStableForSales.rejected, (state: StableStateType, action: any) => {
            state.loading = false;
        });
    },
});

export default stableSlice.reducer;
