
import {useEffect} from 'react'
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "@/store/logs/logsManagementSlice";
import { logKeys } from "./logKeys";
import { AppDispatch } from '@/store';

type HandleSelectCallback = (value: any, type: any) => void;

const keyPhrase = (key: any) => {

    switch (key) {
        case 'is_active':
            return 'Status';
        case 'feedback_balance':
            return 'Feedback balance';
        case 'sms_balance':
            return 'SMS balance';
        case 'devicelicense_account_count':
            return 'License count';
        default:
            return key;
    }

}

export const logsColumns = (handleSelect: HandleSelectCallback, clearStatusFilter: () => void, clearCompanyIdFilter: () => void, logCompanies: any, users: any) => {

    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            }
        },
        {
            title: 
                <Select 
                    style={{width: '100%'}}
                    placeholder='Company'
                    showSearch
                    allowClear
                    onSelect={(val) => handleSelect(val, 'company_id')}
                    onClear={clearCompanyIdFilter}
                >
                    {logCompanies.map((company: any) => {
                        return <Select.Option value={company.company_id}>{company.company_name}</Select.Option>  
                    })}
                </Select>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.company_name}</span>;
            }
        },
        {
            title: 
                <Select 
                    style={{width: '100%'}}
                    placeholder='User'
                    showSearch
                    allowClear
                    onSelect={(val) => handleSelect(val, 'user_email')}
                >
                    {users.map((user: any) => {
                        return <Select.Option value={user.email}>{user.email}</Select.Option>  
                    })}
                </Select>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.user_email}</span>;
            }
        },
        {
            title: 
                <Select 
                    style={{width: '100%'}}
                    placeholder='Action'
                    onSelect={(val) => handleSelect(val, 'status')}
                    allowClear
                    onClear={clearStatusFilter}
                >
                    {logKeys().map((type: any) => {
                        return <Select.Option value={type}>{keyPhrase(type)}</Select.Option>  
                    })}
                </Select>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{keyPhrase(data.log_key)}</span>;
            }
        },
        {
            title: <span className='menuName'>Old data</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.old_data}</span>;
            }
        },
        {
            title: <span className='menuName'>New data</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.new_data}</span>;
            }
        },
        {
            title: <span className='menuName'>Date</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.created_at}</span>;
            }
        },
    ]
}