import { createSlice } from '@reduxjs/toolkit';

export type ModalType = {
    open: boolean;
    modalHeader: string;
    type: string;
    action: 'add' | 'edit' | 'delete';
};

const initialState: ModalType = {
    open: false,
    modalHeader: '',
    type: '',
    action: 'add',
};

export const localSlice = createSlice({
    name: 'localState',
    initialState,
    reducers: {
        toggleModal: (store, { payload: { text, type, action } }) => {
            store.modalHeader = text;
            store.open = !store.open;
            store.type = type;
            store.action = action;
        },
    },
});

export const { toggleModal } = localSlice.actions;

export default localSlice.reducer;
