import { createSlice } from '@reduxjs/toolkit';
import { CustomerStateType, CustomerFormType, CustomerFormPayload } from '@/types/customer';
import { createCustomer, getCustomerById, getCustomers } from './action';

const initialState: CustomerStateType = {
    customers: [],
    count: 0,
    customerForm: {
        name: 'ARTA INTERNATIONAL TEKS.PAZ.SAN.VE TİC.LTD.ŞTİ.',
        email: 'info@ar-ta.net',
        address: 'ÇELİKAY CD.NO:15 GİRİŞ KATI',
        webpage: '',
        country: 3,
        branch: 2,
        employee_count: 0,
        status: 4,
        contacts: [
            {
                full_name: '',
                email: '',
                phone_number: '',
            },
        ],
        users_access_by_ids: [2, 3],
    } as CustomerFormType,
    loading: false,
};

export const userManagementSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomerFormItem: (
            state,
            { payload: customerFormItem }: { payload: CustomerFormPayload },
        ) => {
            let newCustomerForm: CustomerFormType;
            const oldCustomerForm = {
                ...state.customerForm,
                contacts: [...state.customerForm.contacts],
            } as CustomerFormType;

            if (customerFormItem.tab === 'contacts') {
                const oldContact = {
                    ...state.customerForm.contacts[customerFormItem.index!],
                    [customerFormItem.field]: customerFormItem.value,
                };
                oldCustomerForm.contacts.splice(customerFormItem.index!, 1, oldContact);
                newCustomerForm = oldCustomerForm;
            } else {
                newCustomerForm = {
                    ...oldCustomerForm,
                    [customerFormItem.field]: customerFormItem.value,
                } as CustomerFormType;
            }
            state.customerForm = newCustomerForm;
        },
        addCustomerContactArea: (state) => {
            const oldCustomerForm = state.customerForm;
            const newCustomerForm = {
                ...oldCustomerForm,
                contacts: [
                    ...oldCustomerForm.contacts,
                    {
                        full_name: null,
                        email: null,
                        phone_number: null,
                    },
                ],
            } as CustomerFormType;
            state.customerForm = newCustomerForm;
        },
        removeCustomerContactArea: (state, { payload: { index } }) => {
            const newCustomerForm = {
                ...state.customerForm,
                contacts: [...state.customerForm.contacts],
            } as CustomerFormType;
            newCustomerForm.contacts.splice(index, 1);
            state.customerForm = newCustomerForm;
        },
    },
    extraReducers(builder) {
        // Get customers action status
        builder.addCase(getCustomers.pending, (state: CustomerStateType) => {
            state.loading = true;
        });
        builder.addCase(getCustomers.fulfilled, (state: CustomerStateType, action) => {
            state.customers = action.payload;
            state.loading = false;
        });
        builder.addCase(getCustomers.rejected, (state: CustomerStateType) => {
            state.loading = false;
        });

        // Get single user action status
        builder.addCase(getCustomerById.pending, (state: CustomerStateType) => {
            state.loading = true;
        });
        builder.addCase(getCustomerById.fulfilled, (state: CustomerStateType, action) => {
            state.customerForm = action.payload;
            state.loading = false;
        });
        builder.addCase(getCustomerById.rejected, (state: CustomerStateType) => {
            state.loading = false;
        });

        // Create user action status
        builder.addCase(createCustomer.pending, (state: CustomerStateType) => {
            state.loading = true;
        });
        builder.addCase(createCustomer.fulfilled, (state: CustomerStateType) => {
            state.loading = false;
        });
        builder.addCase(createCustomer.rejected, (state: CustomerStateType) => {
            state.loading = false;
        });
    },
});

export const { setCustomerFormItem, addCustomerContactArea, removeCustomerContactArea } =
    userManagementSlice.actions;

export default userManagementSlice.reducer;
