import { IRootStore } from '@/store';
import { ModalType } from '@/store/modal/modalSlice';
import { Button, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';

const ModalFooter = ({
    loading,
    deleteButtonClick,
}: {
    deleteButtonClick?: () => void;
    loading: boolean;
}) => {
    const { action } = useSelector<IRootStore, ModalType>((state) => state.localReducer);
    return (
        <div className='modalFooter'>
            {action === 'edit' ? (
                
                <Button style={deleteButtonClick ? {} : {visibility: 'hidden'}} onClick={deleteButtonClick} className='delete danger'>Delete</Button>
            ) : null}
            <Button
                htmlType='submit'
                style={{ marginLeft: action === 'add' || 'delete' ? 'auto' : '', backgroundColor: action === 'delete' ? '#ff0000': '' }}
                className='success save'
                loading={loading}
            
            >
                {action === 'delete' ? 'Delete' : 'Save'}
            </Button>
        </div>
    );
};

export default ModalFooter;
