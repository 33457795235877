import CustomModal from '@/components/common/modal'
import ModalFooter from '@/components/common/modal/ModalFooter';
import {App, Form, Tabs, message} from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { IRootStore } from '@/store';
import { ModalType } from '@/store/modal/modalSlice';
import GetTabItems from './getTabItems';
import { toggleModal } from '@/store/modal/modalSlice';
import { getLicenses, updateLicense } from '@/store/licenses/action';
import { ILicenseState } from '@/types/license';
import { AppDispatch } from '@/store';
import { filter } from 'lodash';
import { setLicenceModalDetails } from '@/store/licenses/licensesManagementSlice';

const CompanyManagementForm = () => {
    
    const dispatch = useDispatch<AppDispatch>()
    const [activeTab, setActiveTab] = useState('1');
    const [form] = Form.useForm();
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);
    const {getFilter, updFilter, licensesLoading} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);

    const items = GetTabItems();

    const handleSubmitLicense = () => {

        switch (activeTab) {
            case '1':
                dispatch(updateLicense(updFilter)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(getLicenses(getFilter));
                    form.resetFields();
                    message.success('License updated successfully', 2);
                });
                break;
            case '2':
                dispatch(updateLicense(updFilter)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(getLicenses(getFilter));
                    form.resetFields();
                    message.success('License updated successfully', 2);
                });
                break;
            case '3':
                dispatch(updateLicense(updFilter)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(getLicenses(getFilter));
                    form.resetFields();
                    message.success('License updated successfully', 2);
                });
                break;
            case '4':
                dispatch(updateLicense(updFilter)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(getLicenses(getFilter));
                    form.resetFields();
                    message.success('License updated successfully', 2);
                });
                break;
            default:
                break;
        }
    }


    const handleCancelModal = () => {
        form.resetFields();
        dispatch(toggleModal({ text: '', type: '', action: '' }));
    };

  return (
    <CustomModal onCancelModal={handleCancelModal}>
        
        <Form form={form} onFinish={handleSubmitLicense}>
            <div className="companyManagementForms">
            <Tabs
                type='card'
                defaultActiveKey={activeTab}
                items={items}
                onChange={(e: string) => setActiveTab(e)} 
            />
            </div>
            <ModalFooter loading={licensesLoading} />
        </Form>
    </CustomModal>
  )
}

export default CompanyManagementForm