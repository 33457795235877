import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api';

export const getCountriesAndCompanies = createAsyncThunk(
    'getCountriesAndCompanies',
    async (params: any, { rejectWithValue }) => {
        try {
            const getCountries = axiosInstance.get('/country/', { params });
            const getCompanies = axiosInstance.get('/company/', { params });
            const [countries, companies] = await Promise.all([getCountries, getCompanies]);

            return {
                countries: countries.data,
                companies: companies.data,
            };
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const getStableForUser = createAsyncThunk(
    'getStableForUser',
    async (params: any, { rejectWithValue }) => {
        try {
            const result = axiosInstance.get('/metadata/', { params });
            return (await result).data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
export const getStableForCustomer = createAsyncThunk(
    'getStableForCustomer',
    async (params: any, { rejectWithValue }) => {
        try {
            const getBranches = axiosInstance.get('/', { params });
            const getStatuses = axiosInstance.get('/', { params });
            const getUsers = axiosInstance.get('/', { params });
            const result = Promise.all([getBranches, getStatuses, getUsers]);
            return result;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
export const getStableForSales = createAsyncThunk(
    'getStableForSales',
    async (params: any, { rejectWithValue }) => {
        try {
            const getUsers = axiosInstance.get('/', { params });
            const getCustomers = axiosInstance.get('/', { params });

            const result = Promise.all([getCustomers, getUsers]);
            return result;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
