import {ReactElement, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
    PAGE_CUSTOMERS_PATH,
    PAGE_E_MAIL_TRACKER_PATH,
    PAGE_HOME_PATH,
    PAGE_LOGIN_PATH,
    PAGE_SALES_PATH,
    PAGE_USER_MANAGEMENT_PATH,
    PAGE_LICENSES_PATH,
    PAGE_LOGS_PATH,
    PAGE_AI_PATH
} from '@/constants/path';
import Spinner from '@/components/common/Spinner';
import ProtectedRoute from '@/components/auth/ProtectedRoute';
import MainPageLayout from '@/containers/MainPage/MainPageLayout';
import Licenses from './pages/contentPages/Licenses';
import UsersManagement from '@/pages/contentPages/UserManagement';
import Logs from '@/pages/contentPages/Logs';
import Home from '@/pages/contentPages/Dashboard';
import Customers from '@/pages/contentPages/Customers';
import EmailTracker from '@/pages/contentPages/EmailTracker';
import Sales from '@/pages/contentPages/Sales';
import Login from '@/pages/auth/LoginPage';
import AI from './pages/contentPages/AI';

export default function Routing(): ReactElement {
    return (
        <BrowserRouter>
            {/* <Suspense fallback={<Spinner />}> */}
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path={PAGE_HOME_PATH} element={<MainPageLayout />}>
                            <Route index element={<Home />} />
                            <Route path={PAGE_USER_MANAGEMENT_PATH} element={<UsersManagement />} />
                            <Route path={PAGE_CUSTOMERS_PATH} element={<Customers />} />
                            <Route path={PAGE_E_MAIL_TRACKER_PATH} element={<EmailTracker />} />
                            <Route path={PAGE_SALES_PATH} element={<Sales />} />
                            <Route path={PAGE_LICENSES_PATH} element={<Licenses />} />
                            <Route path={PAGE_LOGS_PATH} element={<Logs />} />
                            <Route path={PAGE_AI_PATH} element={<AI />} />
                        </Route>
                    </Route>
                    <Route path={PAGE_LOGIN_PATH} element={<Login />} />
                    <Route path='*' element={<div>404 Not Found</div>} />
                </Routes>
            {/* </Suspense> */}
        </BrowserRouter>
    );
}
