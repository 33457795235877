import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

export const getAiPromptList = createAsyncThunk(
    'getAiPromptList',
    async (_, { rejectWithValue }) => { // Removed the empty object argument
        try {
            const result = await axiosInstance.get('/aiprompt/');
            return result.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const postAiPrompt = createAsyncThunk(
    'postAiPrompt',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.post('/aiprompt/', data);
            return result.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateAiPrompt = createAsyncThunk(
    'updateAiPrompt',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.put(`/aiprompt/${data.id}/`, data);
            return result.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteAiPrompt = createAsyncThunk(
    'deleteAiPrompt',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.delete(`/aiprompt/${data.id}/`);
            return result.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
