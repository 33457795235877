import { Button } from 'antd';

const TableColumns = (editUserAction: (id: number) => void) => {
    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            },
        },
        {
            title: <span className='menuName'>First name</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.first_name}</span>;
            },
        },
        {
            title: <span className='menuName'>Last name</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.last_name}</span>;
            },
        },
        {
            title: <span className='menuName'>phone_number</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.phone_number}</span>;
            },
        },
        {
            title: <span className='menuName'>Role</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.role__name}</span>;
            },
        },
        {
            title: <span className='menuName'>Actions</span>,
            render: ({ id }: any) => {
                return (
                    <Button onClick={() => editUserAction(id)} className='tableEditBtn'>
                        Edit
                    </Button>
                );
            },
        },
    ];
};

export default TableColumns;
