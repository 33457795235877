import { setFilter } from '@/store/dashboard/dashboardSlice';
import { DatePicker, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

const DateRangeSelect = () => {

    const dispatch = useDispatch()
    const {filter} = useSelector((state: any) => state.dashboardReducer);

    const handleDateChange = (date: any, dateString: any) => {
        dispatch(setFilter({...filter, customDate: dateString, predefinedDate: null}))
    };

    return (
        <Input.Group compact>
            <DatePicker.RangePicker allowClear={false} style={{ width: 295 }} value={filter.customDate ? [dayjs(filter?.customDate[0], 'YYYY-MM-DD'), dayjs(filter?.customDate[1], 'YYYY-MM-DD')] : undefined}  onChange={handleDateChange} />
        </Input.Group>
    );
};

export default DateRangeSelect;
