import { Button } from "antd";


export const aiColumns = (editPromptAction: (data: any) => void) => {
    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            },
            width: '10%'
        },
        {
            title: <span className='menuName'>Label</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.label}</span>;
            },
            width: '30%'
        },
        {
            title: <span className='menuName'>Prompt</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.prompt}</span>;
            },
            width: '50%'
        },
        {
            title: <span className='menuName'>Actions</span>,
            render: (data: any) => {
                return <Button onClick={() => editPromptAction(data)} className='tableEditBtn'>Edit</Button>;
            },
            width: '10%'
        },
    ]
}