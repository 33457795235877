import { useSelector } from 'react-redux';
import { TabsProps } from 'antd';
import { IRootStore } from '@/store';
import { ModalType } from '@/store/modal/modalSlice';
import { AiOutlineUser, AiOutlineUnlock, AiOutlineLock } from 'react-icons/ai';
import {RiFeedbackLine} from 'react-icons/ri';
import {MdOutlineTextsms} from 'react-icons/md';
import {GiUpgrade} from 'react-icons/gi';

import { CompanyDetailsTab } from './CompanyDetailsTab';
import {WebBalance} from './WebBalance';
import { SmsBalance } from './SmsBalance';
import { LicenseUpgrade } from './LicenseUpgrade';
const GetTabItems = (): TabsProps['items'] => {
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);

    return [
        {
            key: '1',
            label: (
                <div className='tabMenuContainer'>
                    <AiOutlineUser />
                    <div className='tabMenuName'>Company details</div>
                </div>
            ),
            children: <CompanyDetailsTab />,
        },
        {
            key: '2',
            label: (
                <div className='tabMenuContainer'>
                    <RiFeedbackLine />
                    <div className='tabMenuName'>Web feedback balance</div>
                </div>
            ),
            children: <WebBalance />,
        },
        {
            key: '3',
            label: (
                <div className='tabMenuContainer'>
                    <MdOutlineTextsms />
                    <div className='tabMenuName'>SMS balance</div>
                </div>
            ),
            children: <SmsBalance />,
        },
        {
            key: '4',
            label: (
                <div className='tabMenuContainer'>
                    <GiUpgrade />
                    <div className='tabMenuName'>License upgrade</div>
                </div>
            ),
            children: <LicenseUpgrade />,
        }
    ];
};

export default GetTabItems;
