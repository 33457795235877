import {useEffect} from 'react'
import CustomModal from '@/components/common/modal'
import { Form, Input} from 'antd'
import ModalFooter from '@/components/common/modal/ModalFooter'
import { useDispatch, useSelector } from 'react-redux'
import { toggleModal } from '@/store/modal/modalSlice'
import { setAiModalDetails } from '@/store/ai/aiManagementSlice'
import { postAiPrompt, updateAiPrompt, getAiPromptList, deleteAiPrompt } from '@/store/ai/action'
import { AppDispatch } from '@/store'

const AiManagementForm = () => {

    const dispatch = useDispatch<AppDispatch>()
    const {aiModalDetails, aiLoading} = useSelector((store: any) => store.aiManagementReducer);
    const { action } = useSelector((store: any) => store.localReducer);
    const [form] = Form.useForm();

    const handleCancelModal = () => {
        dispatch(setAiModalDetails(null))
        dispatch(toggleModal({ text: '', type: '', action: '' }));
        form.resetFields();
    }

    useEffect(() => {
        form.setFieldsValue(aiModalDetails)
    }, [aiModalDetails])

    const handleSubmitForm = () => {
        if(action === 'add') {
            dispatch(postAiPrompt(aiModalDetails) as any).then(() => {
                dispatch(toggleModal({ text: '', type: '', action: '' }));
                dispatch(setAiModalDetails(null))
                form.resetFields();
                dispatch(getAiPromptList())
            })
        } else if(action === 'edit') {
            dispatch(updateAiPrompt(aiModalDetails) as any).then(() => {
                dispatch(toggleModal({ text: '', type: '', action: '' }));
                dispatch(setAiModalDetails(null))
                form.resetFields();
                dispatch(getAiPromptList())
            })
        }
    }

    const handleAiDetails = (field: string) => {
        dispatch(setAiModalDetails({...aiModalDetails, [field]: form.getFieldValue(field)}))
    }

    const handleDeleteAiPrompt = () => {
        dispatch(toggleModal({ text: '', type: '', action: '' }));
        dispatch(toggleModal({ text: 'Delete prompt', type: 'prompt', action: 'delete' }))
    }

    const handleDeletePromptFinish = () => {
        dispatch(deleteAiPrompt(aiModalDetails) as any).then(() => {
            dispatch(toggleModal({ text: '', type: '', action: '' }));
            dispatch(setAiModalDetails(null))
            form.resetFields();
            dispatch(getAiPromptList())
        })
    }

  return (
    <CustomModal onCancelModal={handleCancelModal}>
        {action === 'delete' ? (
            <div style={{padding: '1rem'}}>
                <p>Are you sure you want to delete this prompt?</p>
                <Form onFinish={handleDeletePromptFinish}>
                    <ModalFooter loading={false} />
                </Form>
            </div>
        ) : (
            <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
                <div className='aiManagementForm'>
                    <Form.Item
                        label='Label'
                        name={'label'}
                    >
                        <Input value={aiModalDetails?.label} onChange={() => handleAiDetails('label')} />
                    </Form.Item>
                    <Form.Item
                        label='Prompt'
                        name={'prompt'}
                    >
                        <Input.TextArea 
                            value={aiModalDetails?.prompt} 
                            onChange={() => handleAiDetails('prompt')}
                            style={{height: '100px'}}
                        />
                    </Form.Item>
                </div>
                <ModalFooter deleteButtonClick={handleDeleteAiPrompt} loading={aiLoading} />
            </Form>
        )}
        
    </CustomModal>
  )
}

export default AiManagementForm