import { Row} from "antd"

import Select from '@/components/common/forms/Select';
import { useSelector, useDispatch } from "react-redux";
import { IRootStore } from "@/store";
import { ILicenseState } from "@/types/license";
import { AppDispatch } from "@/store";
import  {setLicenceModalDetails, setUpdFilters} from "@/store/licenses/licensesManagementSlice";
export const CompanyDetailsTab = () => {

    const statusOptions = [
        {
            id: 'Approved',
            name: 'Approved'
        },
        {
            id: 'Pending',
            name: 'Pending'
        },
        {
            id: 'Denied',
            name: 'Denied'
        }
        
    ]

    const dispatch = useDispatch<AppDispatch>()

    const {licenceModalDetails, updFilter} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);

    const onChange = (value: any) => {
        dispatch(setUpdFilters({id: licenceModalDetails.id, is_active: value}))
    }

    return (
        <Row className="row">
            <Select
                options={statusOptions}
                defaultValue={licenceModalDetails.is_active}
                label="Status"
                required={true}
                placeholder="Select status"
                fieldName="status"
                onChange={onChange}
            />
        </Row>
    )
}