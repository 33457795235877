import React, {useEffect} from 'react';
import { Layout, Menu } from 'antd';
import { menuItems } from '@/resources/mainPage/menuItems';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useNavigate, useLocation } from 'react-router-dom';
import QmeterLogoShort from '@/components/common/logo/qmeterLogoShort';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import { checkMe } from '@/store/auth/action';
const { Sider } = Layout;

const MainPageSider = ({
    collapsed,
    setCollapsed,
}: {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const {user} = useSelector((store: any) => store.authReducer);
    useEffect(() => {
        if (Object.getPrototypeOf(user) === Object.prototype) {
            dispatch(checkMe({}))
        }

    }, []);
    const location = useLocation(); 
    const navigation = useNavigate();

    const changeRoute = (e: MenuInfo) => {
        navigation(e.key);
        window.scrollTo(0, 0);
    };

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className='rootLayoutSider'
        >
            {!collapsed && <QmeterLogoShort />}
            {!collapsed && <div className='menuHeaderText'>Navigator</div>}
            <Menu
                theme='dark'
                defaultSelectedKeys={['1']}
                
                selectedKeys={[location.pathname]}
                mode='inline'   
                items={menuItems}
                className='rootLayoutMenu'
                onClick={changeRoute}
            />
        </Sider>
    );
};

export default MainPageSider;
