import { Button, Input, PaginationProps, Table, Select } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import { setFilters} from '@/store/licenses/licensesManagementSlice';
import { getLicenses } from '@/store/licenses/action';

type CommonPageTableProps = {
    columns: any[];
    data: any[];
    total?: number;
    setPagination?: Function;
    setSearchValue?: Function;
    loading?: boolean;
    searchTab?: boolean;
    paginationOptions?: any;
    name?: string;
    setSortObj?: Function;
};

const statusOptions = [
    {
        id: 'Approved',
        name: 'Approved'
    },
    {
        id: 'Pending',
        name: 'Pending'
    },
    {
        id: 'Denied',
        name: 'Denied'
    }
    
]

const CommonPageTable = ({
    columns,
    data,
    total,
    setPagination,
    setSearchValue,
    loading,
    searchTab,
    paginationOptions,
    name,
    setSortObj
}: CommonPageTableProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const {getFilter} = useSelector((store: any) => store.licensesManagementReducer)
    const onChangeTable = ({ current, pageSize }: PaginationProps, filters: any, sorter: any) => {
        if (setPagination) {
            setPagination({ current, pageSize });
        }

        if(setSortObj) {
            if(sorter.column) {
                const order = sorter.order === 'ascend' ? 'asc' : 'desc'
                let field_name = ''
                if(sorter.column.title.props.children === 'Device count') {
                    field_name = 'devicelicense_account_count'
                } else {
                    field_name = sorter.column.title.props.children.toLowerCase().replace(' ', '_')
                }
                const field_name_lower = sorter.column.title.props.children.toLowerCase().replace(' ', '_')
                setSortObj({type: order, field_name})
            } else {
                setSortObj({})
            }
        }
    };

    const onSearchTable = (e: any) => {
        if (setSearchValue) {
            setSearchValue(e.target.value);
        }
    };

    const handleStatusFilter = (value: any) => {
        dispatch(setFilters({...getFilter, is_active: value}))
    }

    return (
        <div className='userManagementContent'>
            
            {searchTab && 
            <div className='searchInputContainer'>
                {name === 'licenses' && (
                    <Select

                        placeholder='Status filter'
                        onChange={handleStatusFilter}
                        className='licenceStatusFilter'
                        allowClear
                    >
                        {statusOptions.map((item) => (
                            <Select.Option key={item.id} value={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                    
                )}
                <Input
                    className='searchInput'
                    prefix={<AiOutlineSearch />}
                    placeholder='Type to search and press enter'
                    onPressEnter={onSearchTable}
                />
            </div>}

            <Table
                rowKey={(item) => item.id}
                dataSource={data}
                columns={columns}
                onChange={onChangeTable}
                pagination={paginationOptions ? paginationOptions : false}
                loading={loading}
            />
        </div>
    );
};

export default CommonPageTable;
