import { useState } from "react"
import Input from "@/components/common/forms/Input"
import { useSelector, useDispatch } from "react-redux"
import { IRootStore, AppDispatch } from "@/store"
import { ILicenseState } from "@/types/license"
import { setLicenceModalDetails, setUpdFilters } from "@/store/licenses/licensesManagementSlice"

export const AddNewLicense = ({type}: {type: string}) => {
    const [deviceAmount, setDeviceAmount] = useState<any>(null);
    const dispatch = useDispatch<AppDispatch>()
    const {licenceModalDetails, updFilter} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);

    const handleDeviceChange = (value:any, fieldName: any) => {
        if(type === 'add-extend') {
            dispatch(setUpdFilters({id: licenceModalDetails.id, period: updFilter.period, devicelicense_account_count: Number(value), upgrade_type: type}))
        } else {
            dispatch(setUpdFilters({id: licenceModalDetails.id, devicelicense_account_count: Number(value), upgrade_type: 'add'}))
        }
        setDeviceAmount(value)
    }

    return (
        <Input
            required={true}
            placeholder="Enter device amount"
            type="number"
            style={{borderRadius: 0, width: 200}}
            label="How many device license do you want to add ?"
            onChange={handleDeviceChange}
            defaultValue={deviceAmount}
        /> 
    )
}