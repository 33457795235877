import {getExpireDate, getCountryById, getOverallData, getAdvancedReport, getRatePercent, getFeedBackLines} from './action/index';
import {
    LatestFeedbackType,
    ExpireDateType,
    CountryForExpireType,
} from './../../types/dashboard/index';
import { DashboardStateType } from '../../types/dashboard';
import {createAction, createSlice} from '@reduxjs/toolkit';
import { getLatestFeedback } from './action'
import {OverallDataType} from "@/types/stable";


export const setFilter = createAction<any>('setFilter');
export const clearDashFilters = createAction<any>('clearDashFilters');
export const setAdvancedReportDataSearch = createAction<any>('setAdvancedReportDataSearch');

const initialState: DashboardStateType = {
    filter:{
        country:[],
        company:null,
        predefinedDate:{
            label: null,
            dateRange: {
                first_date: null,
                last_date: null,
            }
        },
        customDate:null,
        refresh: false,
        page: 1,
        page_size: 10
    },
    overallData: {
        country_count: null,
        company_count: null,
        branch_count: null,
        license_device_count: null,
        customer_count: null,
        rate_count: null,
        feedback_count: null,
        last_feedback_time: null

    },
    advancedReportDataSearch: {
        name: null,
        country: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        device_license_account_count: null,
        feedback_count: null,
        branch_count: null,
        field_name: null,
        type: null,
        refresh: null,
    },
    latestFeedback: [] as LatestFeedbackType[],
    latestFeedbackCount: 0,
    advancedReportData: [],
    ratePercentData: [],
    isLoadingRatePercentData: false,
    feedbackLines: [],
    advancedReportcount: 0,
    expireDate: [],
    countryForExpire: {} as CountryForExpireType,
    isLoadingOverallDataInfo: false,
    isLoadingLatestFeedback: false,
    isLoadingExpireDate: false,
    isLoadingCountryForExpire: false,
    isAdvancedReportDataLoading: false,
};


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // Get latestFeedback action status
        builder.addCase(getLatestFeedback.pending, (state: DashboardStateType) => {
            state.isLoadingLatestFeedback = true;
        });
        builder.addCase(
            getLatestFeedback.fulfilled,
            (state: DashboardStateType, { payload }) => {
                const latestFeedback = payload.results
                    ?.map((feedback: any) => {
                        return {
                            id: feedback.company.id,
                            time: feedback.time,
                            country: feedback.country,

                            company: feedback.id,
                            name: feedback.company.name,
                            icons: feedback.icons
                            // country: feedback.country
                        };
                    });

                state.latestFeedback = latestFeedback;
                state.isLoadingLatestFeedback = false;
                state.latestFeedbackCount = payload.count;
            },
        );
        builder.addCase(getLatestFeedback.rejected, (state: DashboardStateType) => {
            state.isLoadingLatestFeedback = false;
        });
        builder.addCase(getRatePercent.pending, (state: DashboardStateType) => {
            state.isLoadingRatePercentData = true;
        });
        builder.addCase(getRatePercent.fulfilled, (state: DashboardStateType, { payload }) => {
                state.ratePercentData = payload;
                state.isLoadingRatePercentData = false;
        });
        builder.addCase(getFeedBackLines.fulfilled, (state: DashboardStateType, { payload }) => {
            state.feedbackLines = payload;
        });
        builder.addCase(getExpireDate.pending, (state: DashboardStateType) => {
            state.isLoadingExpireDate = true;
        });
        builder.addCase(
            getExpireDate.fulfilled,
            (state: DashboardStateType, { payload }: { payload: ExpireDateType[] }) => {
                state.expireDate = payload;
                state.isLoadingExpireDate = false;
            },
        );
        builder.addCase(getExpireDate.rejected, (state: DashboardStateType) => {
            state.isLoadingExpireDate = false;
        });

        builder.addCase(getCountryById.pending, (state: DashboardStateType) => {
            state.countryForExpire = {} as CountryForExpireType;
            state.isLoadingCountryForExpire = true;
        });
        builder.addCase(
            getCountryById.fulfilled,
            (state: DashboardStateType, { payload }: { payload: CountryForExpireType[] }) => {
                if(payload.length > 0) {
                    state.countryForExpire = payload[0];
                } else {
                    state.countryForExpire = {} as CountryForExpireType;
                }
                state.isLoadingCountryForExpire = false;
            },
        );
        builder.addCase(getCountryById.rejected, (state: DashboardStateType) => {
            state.isLoadingCountryForExpire = false;
        });
        builder.addCase(setFilter, (state:DashboardStateType, {payload}):any => {
            if(typeof payload === "object" && Object.hasOwn(payload, 'company')){
                state.filter.company = payload.company
            } if(typeof payload === "object" && Object.hasOwn(payload, 'country')){
                state.filter.country = payload.country
            } if(typeof payload === "object" && Object.hasOwn(payload, 'predefinedDate')){
                state.filter.predefinedDate = payload.predefinedDate
            } if(typeof payload === "object" && Object.hasOwn(payload, 'customDate')){
                state.filter.customDate = payload.customDate
            } if(typeof payload === "object" && Object.hasOwn(payload, 'refresh')){
                state.filter.refresh = payload.refresh
            } if(typeof payload === "object" && Object.hasOwn(payload, 'page')){
                state.filter.page = payload.page
            } if(typeof payload === "object" && Object.hasOwn(payload, 'page_size')){
                state.filter.page_size = payload.page_size
            }

        });
        builder.addCase(clearDashFilters, (state:DashboardStateType) => {
            state.filter = initialState.filter
        });
        builder.addCase(setAdvancedReportDataSearch, (state: DashboardStateType, { payload }): any => {
            if (typeof payload === "object") {
              Object.keys(payload).forEach((key) => {
                if (Object.hasOwnProperty.call(payload, key)) {
                  state.advancedReportDataSearch[key as keyof typeof state.advancedReportDataSearch] = payload[key];
                }
              });
            }
          });
          
        builder.addCase(getOverallData.pending, (state: DashboardStateType) => {
            state.isLoadingOverallDataInfo = true;
        });
        builder.addCase(
            getOverallData.fulfilled,
            (state: DashboardStateType, { payload }: { payload: OverallDataType }) => {
                state.overallData = payload;
                state.isLoadingOverallDataInfo = false;
            },
        );
        builder.addCase(getAdvancedReport.pending, (state: DashboardStateType) => {
            state.isAdvancedReportDataLoading = true;
        });
        builder.addCase(
            getAdvancedReport.fulfilled,
            (state: DashboardStateType, { payload }: { payload: any }) => {
                state.advancedReportData = payload[0]
                state.advancedReportcount = payload[1].count;
                state.isAdvancedReportDataLoading = false;
            }
        );
    },
});

export default dashboardSlice.reducer;
