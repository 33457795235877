import { getLogs, getLogCompanies } from "./actions";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logs: [],
    logsLoading: false,
    error: null,
    logsCount: 0,
    logCompanies: [],
    filters: {
        page_size: 10,
        page_number: 1,
        company_id: null,
        user_email: null,
        status: null,
    }
}

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        setFilters: (state, { payload }) => {
            state.filters = payload;
        },
        clearLogFilters: (state) => {
            state.filters = initialState.filters;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLogs.pending, (state) => {
            state.logsLoading = true;
        });
        builder.addCase(getLogs.fulfilled, (state, { payload }) => {
            state.logsLoading = false;
            state.logs = payload.data;
            state.logsCount = payload.count;
        });
        builder.addCase(getLogs.rejected, (state, { payload }) => {
            state.logsLoading = false;
        });
        builder.addCase(getLogCompanies.pending, (state) => {
            state.logsLoading = true;
        }
        );
        builder.addCase(getLogCompanies.fulfilled, (state, { payload }) => {
            state.logsLoading = false;
            state.logCompanies = payload;
        }
        );
        builder.addCase(getLogCompanies.rejected, (state, { payload }) => {
            state.logsLoading = false;
        }
        );
    }
});

export const {setFilters, clearLogFilters} = logsSlice.actions;   
export default logsSlice.reducer;

