import { Button } from 'antd';

const TableColumns = (editUserAction: any) => {
    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            },
        },
        {
            title: <span className='menuName'>Name</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.name}</span>;
            },
        },
        {
            title: <span className='menuName'>Country</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.country}</span>;
            },
        },
        {
            title: <span className='menuName'>Status</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.status}</span>;
            },
        },
        {
            title: <span className='menuName'>Actions</span>,
            render: ({ id }: any) => {
                return (
                    <Button onClick={() => editUserAction(id)} className='tableEditBtn'>
                        Edit
                    </Button>
                );
            },
        },
    ];
};

export default TableColumns;
