    import { Select } from 'antd';
    import ThirdSection from "@/containers/Dashboard/Tables/sections/ThirdSection";
    import {useDispatch, useSelector} from "react-redux";
    import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";
    import {IRootStore} from "@/store";
    import {DashboardStateType} from "@/types/dashboard";
    import {setFilter} from "@/store/dashboard/dashboardSlice";


    const CompanySelect = ({ options }: any) => {
        // Dependencies
        const {filter} = useSelector<IRootStore, DashboardStateType>(state => state.dashboardReducer);
        // const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const dispatch = useDispatch();



        const handleSelect = (value: any) => {
            dispatch(setFilter({...filter, company:value}));
        }


        return (
            <div>
                <Select 
                    value={filter.company}
                    onSelect={handleSelect}
                    showSearch
                    placeholder='Select by company'
                    filterOption={(input: any, option: any) =>
                        option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                    onClear={() => dispatch(setFilter({
                        ...filter,
                        company:null
                    }))}
                    className='companySelect'
                    options={options?.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })}

                />
            </div>

        );
    };

    export default CompanySelect;
