import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootStore } from '@/store';
import { setSaleFormItem, } from '@/store/sale/saleManagementSlice';
import { StableStateType } from '@/types/stable';
import Select from '@/components/common/forms/Select';
import Input from '@/components/common/forms/Input';
import { ModalType } from '@/store/modal/modalSlice';

export const SaleDetailsForms = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);
    const { branches, jobs, roles, teams } = useSelector<IRootStore, StableStateType>(
        (store) => store.stableReducer,
    );

    const handleChangeSelect = (value: string, key: string) => {

        dispatch(
            setSaleFormItem({
                field: key,
                value,
            }),
        );
    };

    const handleChangeInput = (value: string, key: string) => {
        dispatch(
            setSaleFormItem({
                field: key,
                value,
            }),
        );
    };

    return (
        <Row className='row'>
            <Input
                label='First name'
                required={true}
                placeholder='Enter first name'
                onChange={handleChangeInput}
                rules={[{ required: true, message: 'This field is required.' }]}
                suffix='30'
                fieldName='first_name'
            />
            <Input
                label='Last name'
                required={true}
                placeholder='Enter last name'
                onChange={handleChangeInput}
                fieldName='last_name'
                suffix='30'
                rules={[{ required: true, message: 'This field is required.' }]}
            />
            <Input
                label='E-mail'
                required={true}
                placeholder='Enter e-mail'
                onChange={handleChangeInput}
                type='email'
                fieldName='email'
                rules={[
                    { required: true, message: 'This field is required.' },
                    { type: 'email', message: 'Email is not a valid email!' },
                ]}
            />
            <Input
                label='Phone number'
                required={true}
                placeholder='Enter phone number'
                onChange={handleChangeInput}
                fieldName='phone_number'
                rules={[{ required: true, message: 'This field is required.' }]}
            />
            
            <Select
                options={branches}
                label='Branch'
                required={false}
                placeholder='Select branch'
                onChange={handleChangeSelect}
                fieldName='branch'
            />
            <Select
                options={teams}
                label='Team'
                required={false}
                placeholder='Select team'
                onChange={handleChangeSelect}
                fieldName='team'
            />
            <Select
                options={roles}
                label='User role'
                required={false}
                placeholder='Select role'
                onChange={handleChangeSelect}
                fieldName='role'
            />
            <Select
                options={jobs}
                label='Job title'
                required={false}
                placeholder='Select job'
                onChange={handleChangeSelect}
                fieldName='job'
            />
        </Row>
    );
};
