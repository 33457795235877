import { ACCESS_TOKEN_KEY } from '@/constants/global';
import { PAGE_LOGIN_PATH } from '@/constants/path';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (Boolean(!token)) {
        return <Navigate to={PAGE_LOGIN_PATH} replace />;
    } else {
        return <Outlet />;
    }
};

export default ProtectedRoute;
