import { useSelector, useDispatch } from 'react-redux';
import { IRootStore } from '@/store';
import { DashboardStateType } from '@/types/dashboard';
import { predefinedDates } from '@/mock/dates';
import { setFilter } from '@/store/dashboard/dashboardSlice';
import { getDateRange} from '@/mock/dates';
import { Select } from 'antd';

const PredefinedSelect = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector<IRootStore, DashboardStateType>(state => state.dashboardReducer);

  const handleSelectPredefinedDate = (value: any, label: string) => {
    
    const dateRange = getDateRange(value);

    dispatch(
      setFilter({
        ...filter,
        predefinedDate: {dateRange, label},
        customDate: null,
      })
    );
  };

  return (
    <Select
      className='predefinedSelect'
      options={predefinedDates}
      value={filter.predefinedDate?.label}
      placeholder='Select by date'
      onSelect={(value, option) => handleSelectPredefinedDate(value, option?.label)}
    />
  );
};

export default PredefinedSelect;
