import { updateSale } from './action/index';
import { SaleFormPayload } from './../../types/sale';
import { createSlice } from '@reduxjs/toolkit';
import { SaleStateType, SaleFormType } from '@/types/sale';
import { createSale, getSales } from './action';

const initialState: SaleStateType = {
    sales: [],
    count: 0,
    saleForm: {} as SaleFormType,
    isGetLoading: false,
    isSaveLoading: false,
};

export const saleManagementSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setSaleFormItem: (state, { payload: saleFormItem }: { payload: SaleFormPayload }) => {
            const oldSaleForm = state.saleForm;
            const newSaleForm = {
                ...oldSaleForm,
                [saleFormItem.field]: saleFormItem.value,
            };
            state.saleForm = newSaleForm;
        },
        resetFormFields: (state) => {
            state.saleForm = {} as SaleFormType;
        },
        getSaleById: (state, { payload: id }) => {
            const singleSale = state.sales.find((sale) => sale.id === id);
            if (singleSale) {
                const {
                    id,
                    customer,
                    device_count,
                    date,
                    user,
                } = singleSale;
                const sale = {
                    id,
                    customer,
                    device_count,
                    date,
                    user,
                };
                state.saleForm = sale;
            }
        },
    },
    extraReducers(builder) {
        // Get users action status
        builder.addCase(getSales.pending, (state: SaleStateType) => {
            state.isGetLoading = true;
        });
        builder.addCase(getSales.fulfilled, (state: SaleStateType, { payload }) => {
            state.sales = payload;
            state.isGetLoading = false;
        });
        builder.addCase(getSales.rejected, (state: SaleStateType, action: any) => {
            state.isGetLoading = false;
        });

        // Create user action status
        builder.addCase(createSale.pending, (state: SaleStateType) => {
            state.isSaveLoading = true;
        });
        builder.addCase(createSale.fulfilled, (state: SaleStateType, action) => {
            state.isSaveLoading = false;
        });
        builder.addCase(createSale.rejected, (state: SaleStateType, action: any) => {
            state.isSaveLoading = false;
        });

        // Update user action status
        builder.addCase(updateSale.pending, (state: SaleStateType) => {
            state.isSaveLoading = true;
        });
        builder.addCase(updateSale.fulfilled, (state: SaleStateType, action) => {
            state.isSaveLoading = false;
        });
        builder.addCase(updateSale.rejected, (state: SaleStateType, action: any) => {
            state.isSaveLoading = false;
        });
    },
});

export const { setSaleFormItem, resetFormFields, getSaleById } =
    saleManagementSlice.actions;

export default saleManagementSlice.reducer;
