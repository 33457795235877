import { Button } from 'antd';

export const salesMenuItems = (editSaleAction: (id: number) => void) => {
    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            },
        },
        {
            title: <span className='menuName'>User</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.user}</span>;
            },
        },
        {
            title: <span className='menuName'>Customer</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.customer}</span>;
            },
        },
        {
            title: <span className='menuName'>Count</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.count}</span>;
            },
        },
        {
            title: <span className='menuName'>Date</span>,
            render: ({id}: any) => {
                return <Button onClick={() => editSaleAction(id)} className='tableEditBtn'>Edit</Button>;
            },
        },
    ];
}
