import React from 'react';
type CountryTableRowType = {
    name: string;
    data: string | number;
};
const CountryTableRow = ({ name, data }: CountryTableRowType) => {
    return (
        <tr>
            <td>{name}</td>
            <td>{data || ''}</td>
        </tr>
    );
};

export default CountryTableRow;
