import { createSlice } from "@reduxjs/toolkit";
import { getAiPromptList, postAiPrompt, updateAiPrompt, deleteAiPrompt } from "./action";

const aiSlice = createSlice({
    name: 'ai',
    initialState: {
        aiData: [],
        aiLoading: false,
        aiModalDetails: null,
    },
    reducers: {
        setAiModalDetails: (state, { payload }) => {
            state.aiModalDetails = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAiPromptList.pending, (state) => {
            state.aiLoading = true;
        });
        builder.addCase(getAiPromptList.fulfilled, (state, { payload }) => {
            state.aiLoading = false;
            state.aiData = payload;
        });
        builder.addCase(getAiPromptList.rejected, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(postAiPrompt.pending, (state) => {
            state.aiLoading = true;
        });
        builder.addCase(postAiPrompt.fulfilled, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(postAiPrompt.rejected, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(updateAiPrompt.pending, (state) => {
            state.aiLoading = true;
        });
        builder.addCase(updateAiPrompt.fulfilled, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(updateAiPrompt.rejected, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(deleteAiPrompt.pending, (state) => {
            state.aiLoading = true;
        });
        builder.addCase(deleteAiPrompt.fulfilled, (state, { payload }) => {
            state.aiLoading = false;
        });
        builder.addCase(deleteAiPrompt.rejected, (state, { payload }) => {
            state.aiLoading = false;
        });
    }
})

export const { setAiModalDetails } = aiSlice.actions

export default aiSlice.reducer
