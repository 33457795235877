import { useState } from 'react';
import CommonPageTable from '@/components/common/table/CommonPageTable';
import { customersMockData } from '@/mock/customers';
import getTableColumns from './getTableColumns';
import { AppDispatch, IRootStore } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '@/store/modal/modalSlice';
import { CustomerStateType } from '@/types/customer';

const Customers = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { customerForm } = useSelector<IRootStore, CustomerStateType>(
        (store) => store.customerReducer,
    );
    const customersTableColumns = getTableColumns(editUserAction);
    const [pagination, setPagination] = useState(null);
    const [searchValue, setSearchValue] = useState(null);

    function editUserAction(id: number) {
        // fetch user;
        dispatch(toggleModal({ text: 'Edit customer details', type: 'customers', action: 'edit' }));
    }
    return (
        <div>
            <CommonPageTable
                data={customersMockData.data}
                columns={customersTableColumns}
                setPagination={setPagination}
                setSearchValue={setSearchValue}
                total={100}
                searchTab={true}
            />
        </div>
    );
};

export default Customers;
