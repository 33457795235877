import {useEffect} from 'react'
import CommonPageHeader from '@/components/common/header/CommonPageHeader'
import CommonPageTable from '@/components/common/table/CommonPageTable'
import { aiColumns } from '@/resources/ai/aiColumns'
import { useDispatch, useSelector } from 'react-redux'
import { IRootStore, AppDispatch } from '@/store'
import { toggleModal } from '@/store/modal/modalSlice'
import { setAiModalDetails } from '@/store/ai/aiManagementSlice'
import AiManagementForm from '@/containers/AI/AiManagementForm'
import { getAiPromptList } from '@/store/ai/action'

const AI = () => {

    const dispatch = useDispatch<AppDispatch>()
    const {aiData, aiLoading} = useSelector((store: any) => store.aiManagementReducer);

    useEffect(() => {
        dispatch(getAiPromptList())
    }, [])

    const editPromptAction = (data: any) => {
        console.log(data)
        dispatch(setAiModalDetails(data));
        dispatch(toggleModal({ text: 'Edit prompt', type: 'prompt', action: 'edit' }))
    }

  return (
    <div className='ai'>
        <AiManagementForm />
        <CommonPageHeader 
            header='AI Settings'
            subText=''
            modalHead='Set AI label'
            type='ai'
        />
        <CommonPageTable 
            data={aiData}
            columns={aiColumns(editPromptAction)}
            loading={aiLoading}
            searchTab={false}
            paginationOptions = {false}
        />
    </div>
  )
}

export default AI