import { Col, Row } from "antd";
import OveralData from "../OveralData";
import RatePercent from "../../Graph/RatePercent";
import { memo } from "react";

const SecondSection = () => {
  return (
    <Row className="dashboardSecondSection">
      <Col xs={12} className="col">
        <div className="table_container_rate">
          <RatePercent />
        </div>
      </Col>
      <Col xs={12} className="col">
        <div className="table_container_overal">
          <OveralData />
        </div>
      </Col>
    </Row>
  );
};

export default memo(SecondSection);
