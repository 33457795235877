const QmeterLogoShort = () => {
    return (
        <div className='logo'>
            <svg
                width='121'
                height='30'
                viewBox='0 0 121 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M16.0239 20.6466V14.3268L20.7356 18.7685C25.4218 12.6136 20.1483 4.03496 12.6274 5.11364C10.6609 5.38014 8.80944 6.31922 7.44316 7.75323C1.92698 13.5147 6.53657 23.4258 15.5004 22.3471C15.794 22.3091 16.1005 22.2964 16.3942 22.3598H16.407C16.8411 22.436 17.2242 22.6644 17.5306 22.9817L20.1993 25.672C4.92768 32.5756 -6.09191 14.7202 3.7274 4.31415C15.9217 -7.75438 35.0496 7.88014 24.3492 22.0933L26.022 23.7304V29.9994L16.0239 20.6466Z'
                    fill='white'
                />
                <path
                    d='M30.1602 26.2826H33.7482V17.8689C33.7482 17.4882 33.7738 17.1075 33.8504 16.7394C34.8591 11.5618 42.3289 11.4095 43.6314 16.4856C43.7591 16.9552 43.7974 17.4501 43.7974 17.945V26.2699H47.2833V17.5136C47.2833 16.2572 47.0279 15.0136 46.4916 13.8714C43.5548 7.78006 34.4377 7.55163 31.1689 13.5288C30.4794 14.7851 30.1602 16.2065 30.1602 17.6278V26.2826Z'
                    fill='white'
                />
                <path d='M40.452 17.7285H37.0044V26.2818H40.452V17.7285Z' fill='white' />
                <path
                    d='M66.0155 26.3317L66.0538 22.7657L57.588 22.6769C57.205 22.6769 56.8219 22.6388 56.4516 22.5754C51.2546 21.5221 51.1908 14.0982 56.3111 12.8419C56.7836 12.7276 57.2816 12.6896 57.7796 12.6896L66.156 12.7784L66.1943 9.31394L57.371 9.22511C56.1068 9.21242 54.8427 9.44085 53.7063 9.97384C47.5517 12.8292 47.2197 21.8774 53.1955 25.2023C54.4469 25.9002 55.877 26.2302 57.3199 26.2556L66.0155 26.3317Z'
                    fill='white'
                />
                <path
                    d='M57.5201 16.0193L57.4829 19.4453L66.0883 19.5377L66.1256 16.1116L57.5201 16.0193Z'
                    fill='white'
                />
                <path
                    d='M102.113 26.3317L102.152 22.7657L93.6857 22.6769C93.3027 22.6769 92.9196 22.6388 92.5493 22.5754C87.3523 21.5221 87.2885 14.0982 92.4088 12.8419C92.8813 12.7276 93.3793 12.6896 93.8772 12.6896L102.254 12.7784L102.292 9.31394L93.4686 9.22511C92.2045 9.21242 90.9404 9.44085 89.804 9.97384C83.6493 12.8292 83.3173 21.8774 89.2932 25.2023C90.5446 25.9002 91.9747 26.2302 93.4176 26.2556L102.113 26.3317Z'
                    fill='white'
                />
                <path
                    d='M93.6227 16.0134L93.5854 19.4395L102.191 19.5318L102.228 16.1058L93.6227 16.0134Z'
                    fill='white'
                />
                <path
                    d='M67.8027 9.48047H84.9898V12.7546H78.1073V26.2825H74.5703V12.8053H67.8027V9.48047Z'
                    fill='white'
                />
                <path
                    d='M104.296 9.48047H115.852C116.248 9.48047 116.644 9.50585 117.027 9.59468C121.93 10.6607 122.224 17.6531 117.704 18.9729C119.262 20.5465 120.104 22.9196 120.079 26.2825H116.58C116.593 22.4627 115.227 20.3307 112.635 19.6962C112.137 19.5693 111.626 19.5312 111.115 19.5312H104.296V16.2444H115.418C117.525 16.2444 117.525 12.7292 115.418 12.7292H104.296V9.48047Z'
                    fill='white'
                />
            </svg>
        </div>
    );
};

export default QmeterLogoShort;
