import { useEffect } from 'react';
import { AppDispatch, IRootStore } from '@/store';
import {getCountryById, getLatestFeedback} from '@/store/dashboard/action';
import {Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardStateType } from '@/types/dashboard';
import { DashboardPaginationOptions } from '@/constants/global';
import { toggleModal } from '@/store/modal/modalSlice';
import getlatestFeedbackDateColumns from '@/resources/dashboard/latestFeedbackColumns';
import { setFilter } from '@/store/dashboard/dashboardSlice';

// @ts-ignore
const FeedbackTable = ({company}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { latestFeedback, isLoadingLatestFeedback, filter, latestFeedbackCount } = useSelector<IRootStore, DashboardStateType>(
        (state) => state.dashboardReducer,
    );
    const FeedbackDateColumns = getlatestFeedbackDateColumns(getCompanyById);

    function getCompanyById(id: number) {
        dispatch(
            toggleModal({
                text: `Company details #${id}`,
                type: 'company',
                action: '',
            }),
        );
        dispatch(getCountryById(id));
    }

    useEffect(() => {
       dispatch(getLatestFeedback({page: filter.page, page_size: filter.page_size, company: filter.company, country: filter.country, first_date: filter.customDate?.[0] || filter.predefinedDate.dateRange?.first_date, last_date: filter.customDate?.[1] || filter.predefinedDate.dateRange?.last_date }));
    },[filter])

    const handleRowClick = (id: number) => {

        dispatch(
            toggleModal({
                text: `Company details #${id}`,
                type: 'company',
                action: '',
            }),
        );
        dispatch(getCountryById(id));
    };

    return (
        <>
            <div className='table_inside_container upcoming_table'>
                <div className='tableHeadWithPagination'>
                    <h4 className='tableHeadText'>Latest Feedbacks</h4>
                </div>
                <Table
                    
                    /* pagination={DashboardPaginationOptions} */
                    dataSource={latestFeedback}
                    columns={FeedbackDateColumns}
                    rowKey={(key) => key.company}
                    loading={isLoadingLatestFeedback}
                    onRow={(record: any) => ({
                        onClick: () => handleRowClick(record.id),
                    })}
                    rowClassName="clickable-row"
                    pagination={{
                        current: filter.page,
                        total: latestFeedbackCount,
                        onChange: (page: number, page_size: number) => {
                            dispatch(setFilter({ ...filter, page, page_size }))
                        },
                        pageSize: filter.page_size,
                        showSizeChanger: false,
                    }}
                />
            </div>
        </>
    );
};

export default FeedbackTable;
