import CommonPageTable from '@/components/common/table/CommonPageTable';
import CommonPageHeader from '@/components/common/header/CommonPageHeader';
import { salesMenuItems } from '@/resources/sales/salesMenuItems';
import { salesMockData } from '@/mock/sales';
import ColumnChart from '@/containers/Sales/Graph/ColumnChart';
import { useState } from 'react';
import CreateSaleForm from '@/containers/Sales/CreateSaleForm';
import { useDispatch } from 'react-redux';
import { getSaleById } from '@/store/sale/saleManagementSlice';
import { toggleModal } from '@/store/modal/modalSlice';

const Sales = () => {
    const [pagination, setPagination] = useState(null);
    const [searchValue, setSearchValue] = useState(null);

    const dispatch = useDispatch()

    const editSaleAction = (id: number) => {
        dispatch(getSaleById(id));
        dispatch(toggleModal({ text: 'Edit sale', type: 'sale', action: 'edit' }))
    };

    const salesTableColumns = salesMenuItems(editSaleAction);

   

    return (
        <div className='sales'>
            <CreateSaleForm />
            <CommonPageHeader
                type='sales'
                header='Manage sales'
                subText=''
                modalHead='Create new sale'
            />
            <div className='userManagementContent'>
                <ColumnChart />
            </div>
            <CommonPageTable
                data={salesMockData.data}
                columns={salesTableColumns}
                setPagination={setPagination}
                setSearchValue={setSearchValue}
                total={100}
                searchTab={true}
            />
        </div>
    );
};

export default Sales;
