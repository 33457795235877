import CustomModal from '@/components/common/modal';
import ModalFooter from '@/components/common/modal/ModalFooter';
import { AppDispatch, IRootStore } from '@/store';
import { ModalType, toggleModal } from '@/store/modal/modalSlice';
import {
    createUser,
    deleteUser,
    getUsers,
    updateUser,
    updateUserPass,
} from '@/store/user-management/action';
import { UserStateType } from '@/types/user';
import { Form, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import GetTabItems from './getTabItems';
import { resetFormFields } from '@/store/user-management/userManagementSlice';

const UserManagementForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [activeTab, setActiveTab] = useState('1');
    const [form] = Form.useForm();
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);
    const { userForm, userPassword, isSaveLoading } = useSelector<IRootStore, UserStateType>(
        (store) => store.userManagementReducer,
    );

    const items = GetTabItems();

    const handleCancelModal = () => {
        form.resetFields();
        dispatch(toggleModal({ text: '', type: '', action: '' }));
    };

    const handleSubmitUser = () => {
        if (action === 'edit') {
            const { password, password2 } = userPassword;
            if (password && !password2) {
                form.setFields([
                    {
                        name: 'password2',
                        errors: ['Passwords must match.'],
                    },
                ]);
            } else if (password && activeTab === '2') {
                const requestModel = {
                    id: userForm.id,
                    ...userPassword,
                };
                dispatch(updateUserPass(requestModel)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(resetFormFields());
                    dispatch(getUsers({}));
                    form.resetFields();
                });
            } else {
                const filteredFormField = Object.entries(userForm).filter(
                    (field) => field[1] !== null,
                );
                const requestModel = Object.fromEntries(filteredFormField);

                dispatch(updateUser(requestModel)).then(() => {
                    dispatch(toggleModal({ text: '', type: '', action: '' }));
                    dispatch(resetFormFields());
                    dispatch(getUsers({}));
                    form.resetFields();
                });
            }
        }
        if (action === 'add') {
            const newUser = Object.assign({}, userForm, userPassword);
            dispatch(createUser(newUser)).then(() => {
                dispatch(toggleModal({ text: '', type: '', action: '' }));
                dispatch(resetFormFields());
                dispatch(getUsers({}));
                form.resetFields();
            });
        }
    };

    const handleDeleteUser = () => {
        const { id } = userForm;
        dispatch(deleteUser(id!)).then(() => {
            dispatch(toggleModal({ text: '', type: '', action: '' }));
            dispatch(resetFormFields());
            dispatch(getUsers({}));
            form.resetFields();
        });
    };

    useEffect(() => {
        form.setFieldsValue(userForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userForm]);

    return (
        <CustomModal onCancelModal={handleCancelModal}>
            <Form onFinish={handleSubmitUser} form={form}>
                <div className='userManagementForms'>
                    <Tabs
                        type='card'
                        defaultActiveKey='1'
                        items={items}
                        onChange={(e: string) => setActiveTab(e)}
                    />
                </div>
                <ModalFooter deleteButtonClick={handleDeleteUser} loading={isSaveLoading} />
            </Form>
        </CustomModal>
    );
};

export default UserManagementForm;
