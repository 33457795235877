import {useEffect, useState } from 'react';
import { Input, Table, Divider, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {CheckOutlined, CloseOutlined, SortAscendingOutlined, SortDescendingOutlined, ColumnHeightOutlined} from '@ant-design/icons'
import { getAdvancedReport } from '@/store/dashboard/action';
import { AppDispatch } from '@/store';
import { useSelector, useDispatch } from 'react-redux';
import { setAdvancedReportDataSearch } from '@/store/dashboard/dashboardSlice';
import {debounce} from 'lodash'

const GeneralDashboardTable = () => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch<AppDispatch>();
  
  const {advancedReportData, isAdvancedReportDataLoading, advancedReportcount, advancedReportDataSearch} = useSelector((state: any) => state.dashboardReducer);

  useEffect(() => {
    dispatch(getAdvancedReport({page_size: pageSize, page_number: currentPage, ...advancedReportDataSearch}));
  } , [currentPage, pageSize, advancedReportDataSearch]);

  const customColumnTitle = (title: string, dataIndex: string, inputVis: boolean) => {

    const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>, dataIndex: string) => {
      const value = event.target.value;

      const updatedSearchParam = {
        [dataIndex]: value.length > 0 ? value.toUpperCase() : null
      };

      dispatch(setAdvancedReportDataSearch(updatedSearchParam));
    }, 500)

    const currentSearchParam = advancedReportDataSearch;
    const isSortingField = currentSearchParam.field_name === dataIndex;
    let sortIcon = <ColumnHeightOutlined />;
    
    if (isSortingField) {
      if (currentSearchParam.type === 'asc') {
        sortIcon = <SortAscendingOutlined />;
      } else if (currentSearchParam.type === 'desc') {
        sortIcon = <SortDescendingOutlined />;
      }
    }

    const handleSort = (dataIndex: string) => {
      let updatedType: string | null;
      let updatedFieldName: string | null;
    
      if (currentSearchParam.field_name !== dataIndex) {
        updatedType = 'asc';
        updatedFieldName = dataIndex;
      } else {
        switch (currentSearchParam.type) {
          case 'asc':
            updatedType = 'desc';
            updatedFieldName = dataIndex;
            break;
          case 'desc':
            updatedType = null;
            updatedFieldName = null;
            break;
          default:
            updatedType = 'asc';
            updatedFieldName = dataIndex;
            break;
        }
      }
    
      const updatedSearchParam = {
        field_name: updatedFieldName,
        type: updatedType,
      };
    
      dispatch(setAdvancedReportDataSearch(updatedSearchParam));
    };
    

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 15, whiteSpace: 'nowrap'}}>
          <span>{title}</span>
          <Button onClick={() => handleSort(dataIndex)} className='sortBtn'>
            {sortIcon}
          </Button>

          </div>
        <Divider style={{margin: 5}} />
        <Input style={inputVis ? {} : {visibility: 'hidden'}} autoComplete={"password"} onClick={(e) => e.stopPropagation()}  onChange={(e) => handleSearch(e, dataIndex)} className='searchInput' />
      </div>
    );
  };

  const noInputTitle = (title: string) => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',  whiteSpace: 'nowrap'}}>
        <span>{title}</span>
        <Divider style={{margin: 5}} />
        <Input style={{visibility: 'hidden'}} className='searchInput' />
      </div>
    )
  }

  
  const generalDashTableColumns: ColumnsType<any> = [
    {
      title: noInputTitle('ID'),
      dataIndex: 'id',
      key: 'id',
      
    },
    {
      title: customColumnTitle('Company', 'name', true),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: customColumnTitle('Country', 'country', true),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: customColumnTitle('Registration date', 'created_at', false),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => date === null ? '' : new Date(date).toLocaleDateString(),
    },
    {
      title: customColumnTitle('First Name', 'first_name', true),
      dataIndex: 'first_name',
      key: 'first_name',
      className: 'no-wrap',
    },
    {
      title: customColumnTitle('Last Name', 'last_name', true),
      dataIndex: 'last_name',
      key: 'last_name',
      className: 'no-wrap',
    },
    {
      title: customColumnTitle('Email', 'email', true),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: customColumnTitle('Phone', 'phone_number', true),
      dataIndex: 'phone_number',
      key: 'phone_number',
      className: 'no-wrap',
    },
    {
      title: noInputTitle('Verified'),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (verified: boolean) => (verified ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: customColumnTitle('Licence', 'devicelicense_account_count', false),
      dataIndex: 'devicelicense_account_count',
      key: 'devicelicense_account_count',
      render: (licence: number) => licence || 0,
    },
    {
      title: customColumnTitle('Licence expiry', 'devicelicense_expire_date', false),
      dataIndex: 'devicelicense_expire_date',
      key: 'devicelicense_expire_date',
      render: (date: string) => date === null ? '' : new Date(date).toLocaleDateString(),
    },
    {

      title: noInputTitle('Feedback'),
      dataIndex: 'feedback_count',
      key: 'feedback_count',
    },
    {
      title: customColumnTitle('Branch', 'branch_count', true),
      dataIndex: 'branch_count',
      key: 'branch_count',
    },
    {
      title: customColumnTitle('Last feedback', 'last_feedback', false),
      dataIndex: 'last_feedback',
      key: 'last_feedback',
      render: (date: string) => date === null ? '' : new Date(date).toLocaleDateString(),
    },
    {
      title: noInputTitle('Feedback delta'),
      dataIndex: 'feedback_delta',
      key: 'feedback_delta',
    },
    {
      title: noInputTitle('SMS balance'),
      dataIndex: 'sms_balance',
      key: 'sms_balance',
    },
    {
      title: noInputTitle('Feedback balance'),
      dataIndex: 'feedback_balance',
      key: 'feedback_balance',
    },
  ]; 

  return (
    <div className="layoutDashGeneralTable">
      <Table 
   
        size='small' 
        scroll={{ x: '100%' }} 
        columns={generalDashTableColumns} 
        dataSource={advancedReportData}
        pagination={{
          current: currentPage,
          total: advancedReportcount,
          onChange: (page, page_size) => {
            setCurrentPage(page);
            setPageSize(page_size);
          },
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100', advancedReportcount],
        }}
        loading={isAdvancedReportDataLoading}
      />
    </div>
  );
};

export default GeneralDashboardTable;
