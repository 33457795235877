import { Button } from 'antd';

export const licensesMenuItems = (editLicensesAction: (data: any) => void) => {

    return [
        {
            title: <span className='menuName'>#</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.id}</span>;
            },
        },
        {
            title: <span className='menuName'>Status</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray' style={data.is_active === 'Denied' ? {color: 'red', fontWeight: 500} : (data.is_active === 'Approved' ? {color: 'green', fontWeight: 500} : {color: 'orange', fontWeight: 500})}>{data.is_active}</span>;
            },
        },
        {
            title: <span className='menuName'>Name</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.name}</span>;
            },
            sorter: (a:any, b:any) => {},
        },
        {
            title: <span className='menuName'>Email</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.authcsmuser__email}</span>;
            }
        },
        {
            title: <span className='menuName'>SMS Balance</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.sms_balance}</span>;
            },
            sorter: (a:any, b:any) => {},
        },
        {
            title: <span className='menuName'>Feedback balance</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.feedback_balance}</span>;
            },
            sorter: (a:any, b:any) => {},
        },
        {
            title: <span className='menuName'>Device count</span>,
            render: (data: any) => {
                return <span className='menuValueDarkGray'>{data.devicelicense_account_count || 0}</span>;
            },
            sorter: (a:any, b:any) => {},
        },
        {
            title: <span className='menuName'>Action</span>,
            render: (data: any) => {
                return <Button onClick={() => editLicensesAction(data)} className='tableEditBtn'>Edit</Button>;
            },
        },
    ];
}
