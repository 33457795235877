import { MenuProps } from 'antd';
import {
    DollarOutlined,
    FlagOutlined,
    HomeOutlined,
    UserAddOutlined,
    MailOutlined,
    KeyOutlined,
    DatabaseOutlined,
    RobotOutlined
} from '@ant-design/icons';
import {
    PAGE_HOME_PATH,
    PAGE_USER_MANAGEMENT_PATH,
    PAGE_CUSTOMERS_PATH,
    PAGE_E_MAIL_TRACKER_PATH,
    PAGE_SALES_PATH,
    PAGE_LICENSES_PATH,
    PAGE_LOGS_PATH,
    PAGE_AI_PATH
} from '@/constants/path';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    disabled?: boolean,
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        disabled,
    } as MenuItem;
}

export const menuItems: MenuItem[] = [
    getItem('Dashboard', PAGE_HOME_PATH, <HomeOutlined />),
    getItem('User management', PAGE_USER_MANAGEMENT_PATH, <UserAddOutlined />),
    getItem('E-mail tracker', PAGE_E_MAIL_TRACKER_PATH, <MailOutlined />, undefined, true),
    getItem('Customers', PAGE_CUSTOMERS_PATH, <FlagOutlined />, undefined, true),
    getItem('Sales', PAGE_SALES_PATH, <DollarOutlined />, undefined, true),
    getItem('Licenses', PAGE_LICENSES_PATH, <KeyOutlined />),
    getItem('AI Settings', PAGE_AI_PATH, <RobotOutlined />),
    getItem('Logs', PAGE_LOGS_PATH, <DatabaseOutlined />)
];
