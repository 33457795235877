import { IRootStore } from '@/store';
import { DashboardStateType } from '@/types/dashboard';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import CountryTableRow from './CountryTableRow';

const CountryTable = () => {

    

    const {
        countryForExpire,
        isLoadingCountryForExpire,
      } = useSelector<IRootStore, DashboardStateType>((style) => style.dashboardReducer);


      /* if (!countryForExpire || Object.keys(countryForExpire).length === 0) {
        return <div>No data available</div>;
      } */
      const {
        name,
        country,
        authcsmuser__first_name,
        authcsmuser__last_name,
        authcsmuser__email,
        authcsmuser__phone_number,
        is_active,
        created_at,
        branch_count,
        feedback_count,
        rate_count,
        last_feedback,
        licensedevicelicense__account_count,
        licensedevicelicense__expire_date,
        licensefeedbackbalance__total_balance,
        licensesmsbalance__total_balance,
      } = countryForExpire;
    const fullName = `${authcsmuser__first_name || ''} ${authcsmuser__last_name || ''}`;

    return (
        <>
            {isLoadingCountryForExpire ? (
                <span className='countryForExpireSpinner'>
                    <Spin />
                </span>
            ) : (
                <div className='countryForExpireContainer'>
                    <div className='general-info'>
                        <h4>General info</h4>
                        <table>
                            <tbody>
                                <CountryTableRow name='Company name' data={name} />
                                <CountryTableRow name='Country' data={country} />
                                <CountryTableRow name='Owner name' data={fullName} />

                                <CountryTableRow name='Owner email' data={authcsmuser__email} />
                                <CountryTableRow
                                    name='Owner phone'
                                    data={authcsmuser__phone_number}
                                />
                                <CountryTableRow name='Verified' data={is_active ? 'Yes': 'No'} />
                                <CountryTableRow name='Registration date' data={created_at ? new Date(created_at).toLocaleDateString('en-GB') : ''} />
                                <CountryTableRow
                                    name='License expire'
                                    data={licensedevicelicense__expire_date ? new Date(licensedevicelicense__expire_date).toLocaleDateString('en-GB') : ''}
                                />
                            </tbody>
                        </table>
                    </div>
                    <div className='statistics'>
                        <h4>Statistics</h4>
                        <table>
                            <tbody>
                                <CountryTableRow
                                    name='License count'
                                    data={licensedevicelicense__account_count}
                                />
                                <CountryTableRow
                                    name='SMS balance'
                                    data={licensesmsbalance__total_balance}
                                />
                                <CountryTableRow
                                    name='Web feedback'
                                    data={licensefeedbackbalance__total_balance}
                                />
                                <CountryTableRow name='Branch count' data={branch_count} />
                                <CountryTableRow name='Feedback count' data={feedback_count} />
                                <CountryTableRow name='Rate count' data={rate_count} />
                                <CountryTableRow name='Feedback delta' data={''} />
                                <CountryTableRow name='Last feedback' data={last_feedback ? new Date(last_feedback).toLocaleString('en-GB') : ''} />
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default CountryTable;
