import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

export const getLogs = createAsyncThunk(
    'getLogs',
    async (
      { page_size, page_number, company_id, user_email, status }: {
        page_size: number;
        page_number: number;
        company_id?: number;
        user_email?: string;
        status?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const encodedEmail = user_email ? user_email : undefined;
        const params = {
          page_number,
          page_size,
          company_id,
          user_email: encodedEmail,
          status,
        };
        const result = await axiosInstance.get('/log/', { params });
        return result.data;
      } catch (err: any) {
        return rejectWithValue(err);
      }
    }
  );
  

export const getLogCompanies = createAsyncThunk(
    'getLogCompanies',
    async () => {
        try {
            const result = await axiosInstance.get('/log-company/');
            return result.data;
        } catch (err: any) {
            return err;
        }
    }
);