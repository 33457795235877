import UserManagement from '@/containers/UserManagement';

const UserManagementPage = () => {
    return (
        <div className='userManagement'>
            <UserManagement />
        </div>
    );
};

export default UserManagementPage;
