import CommonPageTable from '@/components/common/table/CommonPageTable';
import CommonPageHeader from '@/components/common/header/CommonPageHeader';
import { customersMockData } from '@/mock/customers';
import { useState } from 'react';

const EmailTracker = () => {
    const [pagination, setPagination] = useState(null);
    const [searchValue, setSearchValue] = useState(null);

    return (
        <div className='emailTracker'>
            <CommonPageHeader
                header='Manage email trackers'
                subText=''
                modalHead='Create new tracker'
                type='email_tracker'
            />
            <CommonPageTable
                data={customersMockData.data}
                columns={[]}
                setPagination={setPagination}
                setSearchValue={setSearchValue}
                total={100}
                searchTab={true}
            />
        </div>
    );
};

export default EmailTracker;
