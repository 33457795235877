import CommonPageHeader from '@/components/common/header/CommonPageHeader'
import CommonPageTable from '@/components/common/table/CommonPageTable'
import { licensesMenuItems } from '@/resources/licenses/licensesMenuItems'
import { useDispatch, useSelector } from 'react-redux'
import { IRootStore, AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { toggleModal } from '@/store/modal/modalSlice'
import CompanyManagementForm from '@/containers/Licenses/CompanyManagementForm'
import { ILicenseState } from '@/types/license'
import { getLicenses } from '@/store/licenses/action'
import {clearFilters, setFilters, setLicenceModalDetails} from '@/store/licenses/licensesManagementSlice'

const Licenses = () => {

  const dispatch = useDispatch<AppDispatch>();


  const editLicensesAction = (data: any) => {
    dispatch(setLicenceModalDetails(data));
    dispatch(toggleModal({ text: 'Edit license', type: 'license', action: 'edit' }))
  }
  const {licenses, licenceCount, licensesLoading, getFilter} = useSelector<IRootStore, ILicenseState>(
    (store) => store.licensesManagementReducer,
  );
  const [searchValue, setSearchValue] = useState(null)
  const [sortObj, setSortObj] = useState({field_name: null, type: null})

  const licensesTableColumns = licensesMenuItems(editLicensesAction)

  useEffect(() => {
    dispatch(getLicenses(getFilter))
  }, [getFilter]);

  useEffect(() => {
    dispatch(setFilters({...getFilter, name: searchValue, field_name: sortObj.field_name, type: sortObj.type}))
  }, [searchValue, sortObj])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])
  


  return (
    <div className='licenses'>
      <CompanyManagementForm />
      <CommonPageHeader 
        header='Manage licenses'
        subText=''
        modalHead='Create new license'
        type='licenses'
      />
      <CommonPageTable
        data={licenses}
        columns={licensesTableColumns}
        searchTab={true}
        paginationOptions = {{
          current: getFilter.page_number,
          total: licenceCount,
          onChange: (page: number, page_size: number) => {
            dispatch(setFilters({...getFilter, page_number: page, page_size: page_size}))
          },
          pageSize: getFilter.page_size,
          pageSizeOptions: ['10', '20', '50', '100', licenceCount],
        }}
        loading={licensesLoading}
        name='licenses'
        setSearchValue={setSearchValue}
        setSortObj={setSortObj}
      />
    </div>
  )
}

export default Licenses