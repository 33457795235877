import { useEffect, useState } from 'react';
import getTableColumns from './upcomingColumns';
import { AppDispatch, IRootStore } from '@/store';
import { getCountryById, getExpireDate } from '@/store/dashboard/action';
import { Button, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardStateType } from '@/types/dashboard';
import { DashboardPaginationOptions } from '@/constants/global';
import CustomModal from '@/components/common/modal';
import { toggleModal } from '@/store/modal/modalSlice';
import CountryTable from './CountryTable';

const UpcomingTable = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [expires, setExpires] = useState(false);
    const [status, setStatus] = useState('all');
    const [expireFilter, setExpireFilter] = useState(null);
    const { filter, expireDate, isLoadingExpireDate } = useSelector<IRootStore, DashboardStateType>(
        (state) => state.dashboardReducer,
    );
    const expireDateColumns = getTableColumns(getCompanyById, 'all');

    useEffect(() => {
        setFilteredData([])
        switch (status) {
            case 'all':
                setFilteredData(expireDate);
                break;
            case 'active':
                const activeData = expireDate.filter((item) => item.is_active && !item.trail_period)
                setFilteredData(activeData);
                break;
            case 'inactive':
                const inactiveData = expireDate.filter((item) => !item.is_active)
                setFilteredData(inactiveData);
                break;
            case 'trial':
                const trialData = expireDate.filter((item) => item.trail_period)
                setFilteredData(trialData);
                break;
        }
    }
    , [expireDate, status]);

    function getCompanyById(id: number) {
        dispatch(
            toggleModal({
                text: `Company details #${id}`,
                type: 'company',
                action: '',
            }),
        );
        dispatch(getCountryById(id));
    }

    const handleCancelModal = () => {
        dispatch(toggleModal({ text: '', type: '', action: '' }));
    };

    useEffect(() => {
        const updatedFilter: { company: null; country: []; first_date?: null; last_date?: null; expired?: any } = {
          company: filter.company,
          country: filter.country,
          first_date: filter.customDate?.[0] || filter.predefinedDate?.dateRange?.first_date,
          last_date: filter.customDate?.[1] || filter.predefinedDate?.dateRange?.last_date,
        };
      
        if (expireFilter) {
          updatedFilter.expired = expireFilter;
        }
      
        dispatch(getExpireDate(updatedFilter));
      }, [filter, expireFilter]);      
      

    const handleExpire = (isExpired: any, expires: boolean) => {
        setExpires(expires);
        const { refresh, ...filteredParams } = filter;
        setExpireFilter(isExpired);
        dispatch(getExpireDate({...filteredParams, expired: isExpired }));
    };

    const handleRowClick = (id: number) => {
        dispatch(
            toggleModal({
                text: `Company details #${id}`,
                type: 'company',
                action: '',
            }),
        );
        dispatch(getCountryById(id));
    };

    const handleFilterChange = (value: string) => {
        setStatus(value);
    };

    return (
        <>
            <CustomModal width={1000} onCancelModal={handleCancelModal}>
                <CountryTable />
            </CustomModal>
            <div className='table_inside_container upcoming_table'>
                <div className='tableHeadWithPagination'>
                    <h4 className='tableHeadText'>Upcoming license Expire</h4>
                    <div className='pagination'>
                        <div className='buttons'>
                            <Select
                                defaultValue='All'
                                className='selectFilter'
                                onChange={(value) => handleFilterChange(value)}
                                placeholder='Select expire date'
                                
                                
                            >
                                <Select.Option value='all'>All</Select.Option>
                                <Select.Option value='active'>Active</Select.Option>
                                <Select.Option value='inactive'>Inactive</Select.Option>
                                <Select.Option value='trial'>Trial</Select.Option>
                            </Select>
                            <Button onClick={() => handleExpire(false, true)} className='upcoming'>
                                <div>Upcoming</div>
                            </Button>
                            <Button onClick={() => handleExpire(true, true)} className='expired'>
                                <div>Expired</div>
                            </Button>
                            
                            {expires && <Button onClick={() => handleExpire(null, false)} className='clearExpired'>Clear</Button>}
                        </div>
                    </div>
                </div>
                <Table
                    pagination={DashboardPaginationOptions}
                    dataSource={filteredData}
                    columns={expireDateColumns}
                    /* rowKey={({ id }) => id} */
                    loading={isLoadingExpireDate}
                    onRow={(record) => {
                        return {
                            onClick: () => handleRowClick(record.id),
                        };
                    }}
                    scroll={{ x: '100%' }} 
                    rowClassName="clickable-row"
                />
            </div>
        </>
    );
};

export default UpcomingTable;
