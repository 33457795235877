import {Radio} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdFilters } from '@/store/licenses/licensesManagementSlice';
import { AppDispatch, IRootStore } from '@/store';
import { ILicenseState } from '@/types/license';


export const ExtendLicense = ({type}: {type: string}) => {
    const dispatch = useDispatch<AppDispatch>()
    const {licenceModalDetails, updFilter} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);
    const handleRadioChange = (e: any) => { 
        if(type === 'add-extend') {
            dispatch(setUpdFilters({id: licenceModalDetails.id, devicelicense_account_count: updFilter.devicelicense_account_count, period: e.target.value, upgrade_type: type}))
        } else {
            dispatch(setUpdFilters({id: licenceModalDetails.id,  period: e.target.value, upgrade_type: 'extend'}))
        }
    }

    return (
        <Radio.Group style={{display: 'flex', flexDirection: 'column', marginLeft: 10, marginTop: 10}} onChange={handleRadioChange} >
            <Radio value={1}>1 months</Radio>
            <Radio value={4}>4 months</Radio>
            <Radio value={6}>6 months</Radio>
            <Radio value={12}>12 months</Radio>
        </Radio.Group>
    )
}