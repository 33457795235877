import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api';

export const getUsers = createAsyncThunk('getUsers', async (params: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.get('/usermanagement/', params);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const createUser = createAsyncThunk('createUser', async (data: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.post(`/usermanagement/`, data);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const updateUser = createAsyncThunk('updateUser', async (data: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.post(`/updateuser/ `, data);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const updateUserPass = createAsyncThunk(
    'updateUserPass',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.post(`/updatepass/ `, data);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const deleteUser = createAsyncThunk(
    'deleteUser',
    async (id: number, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.delete(`/deleteuser/${id}/`);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
