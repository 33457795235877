import { List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {getOverallData} from '@/store/dashboard/action';
import {AppDispatch, IRootStore} from "@/store";
import {DashboardStateType} from "@/types/dashboard";
import {formatTimeAgo} from "@/resources/dashboard/latestFeedbackColumns";

const OveralData = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { filter, overallData, isLoadingOverallDataInfo, isLoadingLatestFeedback, latestFeedback } = useSelector<IRootStore, DashboardStateType>((state) => state.dashboardReducer);

  useEffect(() => {
    // @ts-ignore
    dispatch(getOverallData({ company_id: filter.company, country: filter.country, first_date: filter.customDate?.[0] || filter.predefinedDate.dateRange?.first_date, last_date: filter.customDate?.[1] || filter.predefinedDate.dateRange?.last_date }));
  }, [filter]);

  return (
    <div className='table_inside_container overalDataTable'>
      <h4 className='tableHeadText'>Overall data</h4>
      <List loading={isLoadingLatestFeedback} className='list'>
            <List.Item
              key='countries'
              className='listItem'
            >
              <div className='menuName'>Countries</div>
              <div className='menuValue'>{overallData.country_count}</div>
              <div></div>
            </List.Item>

          <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Companies</div>
              <div className='menuValue'>{overallData.company_count}</div>
              <div></div>
            </List.Item>

            <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Branches</div>
              <div className='menuValue'>{overallData.branch_count}</div>
              <div></div>
            </List.Item>

            <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>License Device</div>
              <div className='menuValue'>{overallData.license_device_count}</div>
              <div></div>
            </List.Item>

            <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Contact info</div>
              <div className='menuValue'>{overallData.customer_count}</div>
              <div></div>
            </List.Item>

            <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Rate / Feedback</div>
              <div className='menuValue'>{overallData.rate_count} / {overallData.feedback_count}</div>
              <div></div>
            </List.Item>

            <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Last feedback</div>
              <div className='menuValue'>{formatTimeAgo(latestFeedback[0]?.time)}</div>
              <div></div>
            </List.Item>

                    <List.Item
              key='companies'
              className='listItem'
            >
              <div className='menuName'>Feedback delta</div>
              <div className='menuValue'></div>
              <div></div>
            </List.Item>

      </List>
    </div>
  );
};

export default OveralData;