import { Col, Form, Input } from 'antd';
import { useState } from 'react';
import CustomLabel from '../Label';

const CustomInput = ({
    label,
    required,
    placeholder,
    defaultValue,
    onChange,
    fieldName,
    rules = [],
    type = 'text',
    suffix,
    style
}: any) => {
    const [value, setValue] = useState(0);
    
    const handleChangeInput = (value: string, fieldName: string) => {
        setValue(value.length);
        onChange(value, fieldName);
    };
    return (
        <Col xs={12} className='col'>
            <CustomLabel label={label} required={required} />
            <Form.Item name={fieldName} className='formItem' rules={rules}>
                {type === 'password' ? (
                    <Input.Password
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        onChange={(e) => handleChangeInput(e.target.value, fieldName)}
                        maxLength={suffix}
                    />
                ) : (
                    <Input
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        onChange={(e) => handleChangeInput(e.target.value, fieldName)}
                        type={type}
                        maxLength={suffix}
                        style={style}
                    />
                )}
            </Form.Item>
            <span style={{ float: 'right' }}>{`${suffix ? value + '/' + suffix : ''}`}</span>
        </Col>
    );
};

export default CustomInput;
