import { updateUser } from './action/index';
import { UserFormPayload, UserPasswordType } from './../../types/user';
import { createSlice } from '@reduxjs/toolkit';
import { UserStateType, UserFormType } from '@/types/user';
import { createUser, getUsers } from './action';

const initialState: UserStateType = {
    users: [],
    count: 0,
    userForm: {} as UserFormType,
    userPassword: {} as UserPasswordType,
    isGetLoading: false,
    isSaveLoading: false,
};

export const userManagementSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUserFormItem: (state, { payload: userFormItem }: { payload: UserFormPayload }) => {
            const oldUserForm = state.userForm;
            const newUserForm = {
                ...oldUserForm,
                [userFormItem.field]: userFormItem.value,
            };
            state.userForm = newUserForm;
        },
        setUserPassword: (state, { payload: userFormItem }: { payload: UserFormPayload }) => {
            const oldUserPassword = state.userPassword;
            const newUserPassword = {
                ...oldUserPassword,
                [userFormItem.field]: userFormItem.value,
            };
            state.userPassword = newUserPassword;
        },
        resetFormFields: (state) => {
            state.userForm = {} as UserFormType;
            state.userPassword = {} as UserPasswordType;
        },
        getUserById: (state, { payload: id }) => {
            const singleUser = state.users.find((user) => user.id === id);
            if (singleUser) {
                const {
                    id,
                    branch,
                    email,
                    first_name,
                    date_joined,
                    is_staff,
                    job,
                    last_name,
                    phone_number,
                    role,
                    team,
                    country_access_by_codes,
                    customer_access_by_ids,
                } = singleUser;
                const user = {
                    id,
                    email,
                    first_name,
                    last_name,
                    phone_number,
                    date_joined,
                    is_staff,
                    job,
                    team,
                    role,
                    branch,
                    country_access_by_codes,
                    customer_access_by_ids,
                };
                state.userForm = user;
            }
        },
    },
    extraReducers(builder) {
        // Get users action status
        builder.addCase(getUsers.pending, (state: UserStateType) => {
            state.isGetLoading = true;
        });
        builder.addCase(getUsers.fulfilled, (state: UserStateType, { payload }) => {
            state.users = payload;
            state.isGetLoading = false;
        });
        builder.addCase(getUsers.rejected, (state: UserStateType, action: any) => {
            state.isGetLoading = false;
        });

        // Create user action status
        builder.addCase(createUser.pending, (state: UserStateType) => {
            state.isSaveLoading = true;
        });
        builder.addCase(createUser.fulfilled, (state: UserStateType, action) => {
            state.isSaveLoading = false;
        });
        builder.addCase(createUser.rejected, (state: UserStateType, action: any) => {
            state.isSaveLoading = false;
        });

        // Update user action status
        builder.addCase(updateUser.pending, (state: UserStateType) => {
            state.isSaveLoading = true;
        });
        builder.addCase(updateUser.fulfilled, (state: UserStateType, action) => {
            state.isSaveLoading = false;
        });
        builder.addCase(updateUser.rejected, (state: UserStateType, action: any) => {
            state.isSaveLoading = false;
        });
    },
});

export const { setUserFormItem, setUserPassword, resetFormFields, getUserById } =
    userManagementSlice.actions;

export default userManagementSlice.reducer;
