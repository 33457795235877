import CommonPageHeader from '@/components/common/header/CommonPageHeader';
import Customers from '@/containers/Customers';

const CustomersPage = () => {
    return (
        <div className='customers'>
            <CommonPageHeader
                header='Manage customers'
                subText=''
                modalHead='Create new customer'
                type='customers'
            />
            <Customers />
        </div>
    );
};

export default CustomersPage;
