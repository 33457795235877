import { AppDispatch, IRootStore } from '@/store';
import { setUserFormItem } from '@/store/user-management/userManagementSlice';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@/components/common/forms/Select';
import { StableStateType } from '@/types/stable';

export const UserAccessManagementForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { companies, countries } = useSelector<IRootStore, StableStateType>(
        (store) => store.stableReducer,
    );

    const handleChangeSelect = (value: string, key: string) => {
        dispatch(
            setUserFormItem({
                field: key,
                value,
            }),
        );
    };

    return (
        <Row className='row'>
            <Select
                options={countries}
                label='Access by countries'
                required={false}
                placeholder='Search by countries'
                onChange={handleChangeSelect}
                colSize={12}
                fieldName='country_access_by_codes'
                mode='multiple'
            />
            <Select
                options={companies}
                label='Access by companies'
                required={false}
                placeholder='Search by companies'
                onChange={handleChangeSelect}
                colSize={12}
                fieldName='customer_access_by_ids'
                mode='multiple'
            />
        </Row>
    );
};
