
import { useEffect } from 'react';
import CustomModal from '@/components/common/modal'
import { ModalType, toggleModal } from '@/store/modal/modalSlice';
import ModalFooter from '@/components/common/modal/ModalFooter';
import { Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { IRootStore } from '@/store';
import { SaleFormType, SaleStateType } from '@/types/sale';
import { createSale, deleteSale, getSales, updateSale } from '@/store/sale/action';
import { AppDispatch } from '@/store';
import { resetFormFields } from '@/store/sale/saleManagementSlice';
import { SaleDetailsForms } from './SaleDetailsForms';


const CreateSaleForm = () => {
    const dispatch = useDispatch<AppDispatch>()

    const [form] = Form.useForm();
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);
    const {saleForm, isSaveLoading} = useSelector<IRootStore, SaleStateType>((store) => store.saleManagementReducer);

    useEffect(() => {
        form.setFieldsValue(saleForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleForm]);

    const handleSubmitSale = () => {
        if (action === 'edit') {
            const filteredFormField = Object.entries(saleForm).filter(
                (field) => field[1] !== null,
            );
            const requestModel = Object.fromEntries(filteredFormField);

            dispatch(updateSale(requestModel)).then(() => {
                dispatch(toggleModal({ text: '', type: '', action: '' }));
                dispatch(resetFormFields());
                dispatch(getSales({}));
                form.resetFields();
            });
            
        }
        if (action === 'add') {
            dispatch(createSale(saleForm)).then(() => {
                dispatch(toggleModal({ text: 'Create sale', type: 'sale', action: '' }));
                dispatch(resetFormFields());
                dispatch(getSales({}));
                form.resetFields();
            });
        }
    };

    const handleCancelModal = () => {
        form.resetFields();
        dispatch(toggleModal({ text: '', type: '', action: '' }));
    };

    const handleDeleteSale = () => {
        const { id } = saleForm;

        dispatch(deleteSale(id!)).then(() => {
            dispatch(toggleModal({ text: '', type: '', action: '' }));
            dispatch(resetFormFields());
            dispatch(getSales({}));
            form.resetFields();
        }
        );
    };

  return (
    <CustomModal onCancelModal={handleCancelModal}>
        <Form onFinish={handleSubmitSale} form={form}>
            
            <div className='userManagementForms'>
                <SaleDetailsForms />
                        
            </div>
            <ModalFooter deleteButtonClick={handleDeleteSale} loading={isSaveLoading} />
        </Form>
        
    </CustomModal>
  )
}

export default CreateSaleForm