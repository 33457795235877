import { Layout, Input, Popconfirm, Spin } from 'antd';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { PAGE_LOGIN_PATH } from '@/constants/path';
import { AuthStateType } from '@/types/auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootStore } from '@/store';
import { logout } from '@/store/auth/action';
const { Header } = Layout;
const { Search } = Input;

// Lauout header
const HeaderComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        user: { first_name, last_name },
    } = useSelector<IRootStore, AuthStateType>((store) => store.authReducer);
    const navigate = useNavigate();

    const logOut = () => {
        navigate(PAGE_LOGIN_PATH);
        dispatch(logout({}));
    };

    const confirm = () => {
        navigate(PAGE_LOGIN_PATH);
        dispatch(logout({}));
    };
    

    const isUserReady = first_name && last_name; // Check if both first_name and last_name are available

    return (
        <Header className='layoutHeader'>
            <div className='headerContainer'>
                <div className='userProfile'>
                    {isUserReady ? (
                        <span className='username'>{`${first_name} ${last_name}`}</span>
                    ) : (
                        <span className='username'>Loading...</span>
                    )}
                    
                    <Popconfirm
                        placement="bottomRight"
                        title={"Are you sure you want to logout?"}
                        description={"You will be redirected to the login page."}
                        onConfirm={confirm}
                        okText="Yes"
                        cancelText="No"
                    >
                        <AiOutlineLogout className='outIcon' size={24} />
                    </Popconfirm>
                </div>
            </div>
        </Header>
    );
};

export default HeaderComponent;

