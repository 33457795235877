import { BaseSyntheticEvent, useState } from 'react';
/* import Input from '@/components/common/forms/Input'; */
import { Row, Button, Input, Col, Form } from "antd"
import { useSelector } from "react-redux";
import { IRootStore } from "@/store";
import { ILicenseState } from "@/types/license";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import { setLicenceModalDetails, setUpdFilters } from '@/store/licenses/licensesManagementSlice';
import CustomLabel from '@/components/common/forms/Label';

export const SmsBalance = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [smsBalance, setSmsBalance] = useState<number>(0);
    const {licenceModalDetails, updFilter} = useSelector<IRootStore, ILicenseState>((store) => store.licensesManagementReducer);

      const handleInputChange = (e: BaseSyntheticEvent) => {
        dispatch(setUpdFilters({id: licenceModalDetails.id, sms_balance: Number(e.target.value)}))
        setSmsBalance(Number(e.target.value))
      };
    
  return (
    <Row className="row">
            <Row style={{width: '100%', alignItems: 'center'}}>
                <Col className='col'>
                    <CustomLabel label="SMS balance" required={true} />
                    <Form.Item className='formItem'>
                        <Input
                            required={true}
                            placeholder="Enter SMS balance"
                            type="number"
                            style={{borderRadius: 0, width: 200}}
                            value={smsBalance}
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
    </Row>
  )
}
