export const customersMockData = {
    total: 56,
    data: [
        {
            id: 132,
            name: 'ARTA INTERNATIONAL TEKS.PAZ.SAN.VE TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'ÇELİKAY CD.NO:15 GİRİŞ KATI',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@ar-ta.net',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 131,
            name: 'ARS EXPO ORGANİZASYON AYAK.VE DERİ SAN.TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'SÜMER MAH.8.SOK.NO.54 DÜKKAN:1 CANSEVER APT.',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'cagatayderi@superonline.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 130,
            name: 'ARPEL DERİ GİY.SAN.VE TİC.LTD. ŞTİ',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'YENİDOĞAN MAH. ZÜBEYDE HANIM CAD. NO:102/1-2',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@arpelderi.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 129,
            name: 'ARMADA DERİ TEKS.VE TUR.HİZM.SAN.TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: '47/2 SOKAK NO:3',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'armadaderi@yahoo.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 128,
            name: 'ARKADYA DERİ ÜRÜN.TEKS.İNŞ.SAN.TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'GÖKALP MH.Ş.KOM.GÜNAYDIN CD.39/A SK. NO:7',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'raffaello02@hotmail.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 127,
            name: 'Arinna Uşak Deri Sanayi A.Ş.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: '',
            webpage: 'www.arinnatanning.com',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'marketing@arinnatanning.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 126,
            name: 'ARİFOĞULLARI DERİ SAN.VE TİC. LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'YENİ TABAKHANELER 6.SOKAK',
            webpage: 'www.arifogullarideri.com.tr',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'arifogullarideri@hotmail.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 125,
            name: 'ARDA ÇANTACILIK VE REKLAM SANAYİ TİCARET LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'YEŞİLTEPE MH.57/17 SK.NO:11',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@ardacanta.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 124,
            name: 'ARAS_A DIŞ TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'İSTKLAL CAD.NO:465 /26_A',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@silco.com.tr',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 123,
            name: 'ARAS ETİKET SAN.VE TİC.A.Ş.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'ORG.SAN.BÖL.BİKSAN KOOP.C BL. NO.4',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@araslabel.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 122,
            name: 'APS TEKS.VE TİC.A.Ş',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'PROF.DR. NECMETTİN ERBAKAN CD. NO:25/A',
            webpage: 'www.apstextile.com',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: '',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 121,
            name: 'ANTREPO MAĞZ.HİZM.TURZ.VE DIŞ TİC.A.Ş.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'KÜÇÜK ORG.SAN.BÖLG.ATATÜRK BLV.CABANİ İŞ MRK.NO:82',
            webpage: 'www.cabani.com.tr',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'e-ticaret@cabani.com.tr',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 120,
            name: 'ANT DIŞ TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'CUMHURİYET MH. 1988 SK. NO:4 PAPATYA RESİDENCE 1',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@ant-ltd.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 119,
            name: 'ANKAPA AYAKKABI TEKSTİL TİC.VE SAN.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: '',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@ankapa.com.tr',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 118,
            name: 'ANDA AYAKKABI VE KALIP SAN.TİCLTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'AKÇABURGAZ MEVKİİ 1592 SK.NO:A7 İÇ KAPI NO:1-2',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'emrecoker@gmail.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 117,
            name: 'ALVA AYAKKABI TABAN ÇELİKLERİ SAN.VE TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'GAZİTEPE MAH. FABRİKALAR CAD. NO: 29',
            webpage: 'www.alva.com.tr',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@alva.com.tr',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 116,
            name: 'ALTUNTUR NAK.İNŞ.İÇ VE DIŞ TİC LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'AYDINLAR MAH.03063 NOLU CAD. NO:25/1',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@altuntur.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 115,
            name: 'VHS Group',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: '',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'vhs@vhsgrup.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 114,
            name: 'ALTIN GÜNGÖR PLASTİK SAN. VE TİC. LTD. ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'ORGANİZE SANAYİ BÖLG.İ ÇEVRE SAN.SİT 9..BLOK   NO: 10-12-14-16',
            webpage: '',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'info@altingungor.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
        {
            id: 113,
            name: 'ALSACE AYAKKABI SAN.VE TİC.LTD.ŞTİ.',
            sector: null,
            parent: null,
            country: 'Turkey',
            address: 'MİMAR KEMALETTİN MH.ŞAİR HAŞMET SK.N.12/2 LALELİ',
            webpage: 'alsaceshoes.com',
            logo_url: null,
            empcount: 0,
            reference: '',
            created_by: 6,
            branch: null,
            status: 4,
            email: 'alsace_shoes@hotmail.com',
            phones: [],
            country_code: 'TR',
            user_access_by_ids: [],
        },
    ],
};
