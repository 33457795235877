import {useEffect} from 'react';
import SliChart from '@/containers/Dashboard/Graph/SliChart';
import TopDashboardSection from '@/containers/Dashboard/Grid/TopDashboardSection';
import GeneralDashboardTable from '@/containers/Dashboard/Tables/GeneralDashboardTable';
import SecondSection from '@/containers/Dashboard/Tables/sections/SecondSection';
import ThirdSection from '@/containers/Dashboard/Tables/sections/ThirdSection';
import { useDispatch } from 'react-redux';
import { clearDashFilters } from '@/store/dashboard/dashboardSlice';
import { AppDispatch } from '@/store';

const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {

        return () => {
            dispatch(clearDashFilters({}));

        }
    }, [])

    return (
        <div className='layoutDashboard'>
            <TopDashboardSection />
            <SecondSection />
            <ThirdSection />
            <SliChart />
            <GeneralDashboardTable />
        </div>
    );
};

export default Dashboard;
