import { useSelector } from 'react-redux';
import { TabsProps } from 'antd';
import { IRootStore } from '@/store';
import { ModalType } from '@/store/modal/modalSlice';
import { AiOutlineUser, AiOutlineUnlock, AiOutlineLock } from 'react-icons/ai';
import { UserAccessManagementForm } from '@/containers/UserManagement/UserManagementForm/UserAccessManagementForm';
import { UserChangePasswordForm } from '@/containers/UserManagement/UserManagementForm/UserChangePasswordForm';
import { UserDetailsForms } from '@/containers/UserManagement/UserManagementForm/UserDetailsForms';

const GetTabItems = (): TabsProps['items'] => {
    const { action } = useSelector<IRootStore, ModalType>((store) => store.localReducer);

    return [
        {
            key: '1',
            label: (
                <div className='tabMenuContainer'>
                    <AiOutlineUser />
                    <div className='tabMenuName'>User details</div>
                </div>
            ),
            children: <UserDetailsForms />,
        },
        ...(action === 'edit'
            ? [
                  {
                      key: '2',
                      label: (
                          <div className='tabMenuContainer'>
                              <AiOutlineLock />
                              <div className='tabMenuName'>Change Password</div>
                          </div>
                      ),
                      children: <UserChangePasswordForm />,
                  },
              ]
            : []),
        {
            key: '3',
            label: (
                <div className='tabMenuContainer'>
                    <AiOutlineUnlock />
                    <div className='tabMenuName'>Access management</div>
                </div>
            ),
            children: <UserAccessManagementForm />,
        },
    ];
};

export default GetTabItems;
