import { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ColumnChart = () => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'column',
        },
        title: {
            text: 'Column Chart',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        },
        yAxis: {
            title: {
                text: 'Y-axis',
            },
        },
        series: [
            {
                name: 'Data Series',
                data: [
                    29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4,
                ],
            },
            
        ],
    });

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default ColumnChart;
