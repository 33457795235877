import { BASE_URL } from './../constants/global';
import { ACCESS_TOKEN_KEY } from '@/constants/global';
import axios, { AxiosRequestConfig } from 'axios';

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (token) {
            (config.headers as any).authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
