import {useRef} from 'react'
import { Affix, Layout } from 'antd';
import Header from '@/components/common/header/Header';
import { Outlet } from 'react-router-dom';
import MainPageSider from './Sider';
import { useEffect, useState } from 'react';
import { gray_tone } from '@/styles/variable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootStore } from '@/store';
import { checkMe } from '@/store/auth/action';
import { AuthStateType } from '@/types/auth';
import { useNavigate } from 'react-router-dom';

const { Content, Footer } = Layout;

const MainPageLayout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const { user } = useSelector<IRootStore, AuthStateType>((store) => store.authReducer);
    const [collapsed, setCollapsed] = useState(false);



    return (
        <Layout id='rootLayout'>
            <div style={{ minHeight: '100%', backgroundColor: gray_tone }}>
                <Affix style={{height: '100%'}}>
                    <MainPageSider collapsed={collapsed} setCollapsed={setCollapsed} />
                </Affix>
            </div>
            <Layout className='site-layout'>
                <Affix >
                    <Header />
                </Affix>
                <Content  className='rootLayoutContent'>
                    <Outlet />
                </Content>
                <Footer></Footer>
            </Layout>
        </Layout>
    );
};

export default MainPageLayout;
