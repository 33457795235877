import {useEffect} from 'react'
import CommonPageHeader from '@/components/common/header/CommonPageHeader'
import CommonPageTable from '@/components/common/table/CommonPageTable'
import { logsColumns } from '@/resources/logs/logsColumns'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, IRootStore } from '@/store'
import { getLogCompanies, getLogs } from '@/store/logs/actions'
import { clearLogFilters, setFilters } from '@/store/logs/logsManagementSlice'
import { getUsers } from '@/store/user-management/action'

const Logs = () => {

    const dispatch = useDispatch<AppDispatch>();
    const {logs, logsLoading, logsCount, filters, logCompanies} = useSelector((state: any) => state.logsManagementReducer)
    const {users} = useSelector((state: any) => state.userManagementReducer)
    useEffect(() => {
        dispatch(getLogs(filters))
        
    }, [filters]);

    const handleSelect = (value: any, type: any) => {
        dispatch(setFilters({...filters, [type]: value}))
    }

    useEffect(() => {
        dispatch(getLogCompanies())
        dispatch(getUsers({}))
        return () => {
            dispatch(clearLogFilters())
        }
    }, [])

    const clearStatusFilter = () => {
        dispatch(setFilters({...filters, status: undefined}));
      };
      
      const clearCompanyIdFilter = () => {
        dispatch(setFilters({...filters, company_id: undefined}));
      };

  return (
    <div className='logs'>
        <CommonPageHeader 
            header='Logs'
            subText=''
            modalHead=''
            type='logs'
        />
        <CommonPageTable 
            data={logs}
            columns={logsColumns(handleSelect, clearStatusFilter, clearCompanyIdFilter, logCompanies, users)}
            loading={logsLoading}
            searchTab={false}
            paginationOptions = {{
                current: filters.page_number,
                total: logsCount,
                onChange: (page: number, page_size: number) => {
                  dispatch(setFilters({...filters, page_number: page, page_size: page_size}))
                },
                pageSize: filters.page_size,
                pageSizeOptions: ['10', '20', '50', '100', logsCount],
            }}
        />
    </div>
  )
}

export default Logs