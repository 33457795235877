import { getLicenses, updateLicense } from "./action";
import { createSlice } from "@reduxjs/toolkit";
import { ILicenseState } from '../../types/license'


const initialState: ILicenseState = {
    licenses: [],
    licensesLoading: false,
    error: null,
    licenceCount: 0,
    licenceModalDetails: null,
    
    getFilter: {
        page_size: 10,
        page_number: 1,
        is_active: null,
        name: null,
        field_name: null,
        type: null,
    },

    updFilter: {
        id: null,
        is_active: null,
        sms_balance: null,
        devicelicense_account_count: null,
        feedback_balance: null,
        period: null,
        upgrade_type: null,
    }
};

const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {
        setFilters: (state, { payload }) => {
            state.getFilter = payload;
        },
        clearFilters: (state) => {
            state.getFilter = initialState.getFilter;
        },
        setLicenceModalDetails: (state, { payload }) => {
            state.licenceModalDetails = payload;
        },
        setUpdFilters: (state, { payload }) => {
            state.updFilter = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLicenses.pending, (state) => {
            state.licensesLoading = true;
        });
        builder.addCase(getLicenses.fulfilled, (state, { payload }) => {
            state.licensesLoading = false;
            state.licenses = payload[0];
            state.licenceCount = payload[1].count;
        });
        builder.addCase(getLicenses.rejected, (state, { payload }) => {
            state.licensesLoading = false;
            state.error = payload;
        });
        builder.addCase(updateLicense.pending, (state) => {
            state.licensesLoading = true;
        });
        builder.addCase(updateLicense.fulfilled, (state, { payload }) => {
            state.licensesLoading = false;
        });
        builder.addCase(updateLicense.rejected, (state, { payload }) => {
            state.licensesLoading = false;
            state.error = payload;
        });
    }
});

export const {setFilters, clearFilters, setUpdFilters, setLicenceModalDetails } = licensesSlice.actions;
export default licensesSlice.reducer;