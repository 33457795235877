import {ACCESS_TOKEN_KEY, BASE_URL} from './../../../constants/global';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginResponseType, LoginType } from '@/types/auth';
import axios from 'axios';
import { axiosInstance } from '@/store/api';

export const login = createAsyncThunk(
    'login',
    async (params: LoginType | any, { rejectWithValue }) => {
        try {
            const result: { data: LoginResponseType } = await axios.post(
                `${BASE_URL}/login/`,
                params,
            );
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);

export const logout = createAsyncThunk('logout', async (id: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.post(`/logout/`);
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const checkMe = createAsyncThunk('checkMe', async (id: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.get(`/me/`);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});
