import { ExpireDateType } from '@/types/dashboard';
import { SortAscendingOutlined, SortDescendingOutlined, ColumnHeightOutlined} from '@ant-design/icons';
import { Tooltip } from 'antd';

export function DateDiff(dateString: any) {
  const date = new Date(dateString);
  const today = new Date();
  if (dateString){
      // @ts-ignore
      const diffTime = Math.abs(date - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 31) {
          return `in ${diffDays} days`;
      } else if (diffDays < 365 && diffDays > 31) {
          const diffMonths = Math.floor(diffDays / 31);
          return `in ${diffMonths} months`;
      } else {
          const diffYears = Math.floor(diffDays / 365);
          return `in ${diffYears} years`;
      }
  }
  return '';
}

const TableColumns = (getCompany: (id: number) => void, filterValue: string) => {
    return [
        {
            title: <span className='menuName'>Status</span>,
            render: ({ is_active, trail_period }: ExpireDateType) => (
                
                <Tooltip title={is_active ? 'Active' : 'Inactive'}>
                    <span className='menuValueDarkGray'>{is_active ? (trail_period ? <div style={{fontWeight: 600, color:'orange',textAlign:'center',paddingLeft:'1px',paddingRight:'1px'}}>Trial</div> : <div style={{fontWeight: 600,color:'green',textAlign:'center',paddingLeft:'1px',paddingRight:'1px'}}>Active</div>) : <div style={{fontWeight: 600,color:'red',textAlign:'center',paddingLeft:'1px',paddingRight:'1px'}}>Inactive</div>}</span>
                </Tooltip>
            ),
        },
        {
            title: <span className='menuName'>Companies </span>,
            render: ({ name, id }: ExpireDateType) => (
                <span /* onClick={() => getCompany(id)} */ className='menuValue companyLink'>
                    {name.length > 15 ? name.slice(0, 15) + "..." : name}
                </span>
            ),
            className: 'no-wrap companyColumn',
            
        },
        {
            title: <span className='menuName'>Countries</span>,
            render: ({ country }: ExpireDateType) => (
                <span className='menuValueDarkGray'>{country.length > 30 ? country.slice(0, 30) + "..." : country}</span>
            ),
        },
        {
            title: <span className='menuName'>Device</span>,
            render: ({ licensedevicelicense__account_count }: ExpireDateType) => (
                <span className='menuValueDarkGray'>{licensedevicelicense__account_count}</span>
            ),
        },
        {
            title: <span className='menuName'>Feedback/SMS</span>,
            render: ({
                licensefeedbackbalance__total_balance,
                licensesmsbalance__total_balance,
            }: ExpireDateType) => (
                <span className='menuValueDarkGray'>
                    {`${licensefeedbackbalance__total_balance} / ${licensesmsbalance__total_balance}`}
                </span>
            ),
        },
        {
            title: <span className='menuName'>Licence expired</span>,
            render: ({ licensedevicelicense__expire_date }: ExpireDateType) => (
                <span className='menuValueDarkGray'>{DateDiff(licensedevicelicense__expire_date)}</span>
            ),
            sorter: (a: any, b: any) => {
                const expireDateA = new Date(a.licensedevicelicense__expire_date).getTime();
                const expireDateB = new Date(b.licensedevicelicense__expire_date).getTime();
                return expireDateA - expireDateB;
            },
            className: 'no-wrap'
        },
        {
            title: <span className='menuName'>Feedback expired</span>,
            render: ({ feedback_expire }: ExpireDateType) => (
                <span className='menuValueDarkGray'>{DateDiff(feedback_expire)}</span>
            ),
            sorter: (a: any, b: any) => {
                const expireDateA = new Date(a.feedback_expire).getTime();
                const expireDateB = new Date(b.feedback_expire).getTime();
                return expireDateA - expireDateB;
            },
            className: 'no-wrap'
        }
    ];
};

export default TableColumns;
