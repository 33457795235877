import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api';

export const getSales = createAsyncThunk('getSales', async (params: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.get('/sales/', params);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const createSale = createAsyncThunk('createSale', async (data: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.post(`/sales/`, data);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const updateSale = createAsyncThunk('updateSale', async (data: any, { rejectWithValue }) => {
    try {
        const result = await axiosInstance.post(`/sale/ `, data);
        return result.data;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

export const deleteSale = createAsyncThunk(
    'deleteSale',
    async (id: number, { rejectWithValue }) => {
        try {
            const result = await axiosInstance.delete(`/sale/${id}/`);
            return result.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    },
);
