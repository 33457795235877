import { AppDispatch } from '@/store';
import { toggleModal } from '@/store/modal/modalSlice';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';

interface ICommonPageHeaderProps {
    header: string;
    subText: string;
    modalHead: string;
    type: 'user_management' | 'email_tracker' | 'customers' | 'sales' | 'licenses' | 'logs' | 'ai';
    onClickAdd?: Function;
}

const CommonPageHeader = ({
    header,
    subText,
    modalHead,
    type,
    onClickAdd,
}: ICommonPageHeaderProps) => {
    const modalAction: string = 'add';
    const dispatch = useDispatch<AppDispatch>();

    const handleClickHeaderAddButton = () => {
        if (onClickAdd) onClickAdd();
        dispatch(toggleModal({ text: modalHead, type, action: modalAction }));
    };
    return (
        <div className='commonPageHeader'>
            <div>
                <h3 className='headText'>{header}</h3>
                <span className='subText'>{subText}</span>
            </div>
            {type  !== 'licenses' && type !== 'logs' &&  (
                <Button onClick={handleClickHeaderAddButton} className='addBtn'>
                Create +
            </Button>
            )}
        </div>
    );
};

export default CommonPageHeader;
