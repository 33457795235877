const CustomLabel = ({ label, required }: any) => {
    return (
        <div>
            <label className='labelName' htmlFor={label}>
                <span>{label}</span>
                <span
                    style={{
                        color: '#e84e40',
                        display: required ? 'inline-block' : 'none',
                        paddingLeft: 4,
                    }}
                >
                    *
                </span>
            </label>
        </div>
    );
};

export default CustomLabel;
