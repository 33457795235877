import {useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
/* import { rates } from '@/mock/rates'; */
import { useSelector, useDispatch } from 'react-redux';
import { getRatePercent } from '@/store/dashboard/action';
import { AppDispatch } from '@/store';


const RatePercent = () => {
    const dispatch = useDispatch<AppDispatch>()
    const {filter, ratePercentData, isLoadingRatePercentData} = useSelector((state: any) => state.dashboardReducer);

    const formattedData = ratePercentData && typeof ratePercentData === 'object'
  ? Object.entries(ratePercentData).map(([name, y]) => ({
      name: name.charAt(0).toUpperCase() + name.slice(1),
      y
    }))
  : [];

    
    const options = {
        chart: {
            type: 'pie',
            className: 'ratePercent',
        },
        title: {
            text: 'Rate percent',
            style: {
                color: '#607d8b',
                fontSize: '17.5px',
            }
        },
        series: [
            {
                data: formattedData,
            },
        ],
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                
            },
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        credits: {
            enabled: false,
        },
        colors: ['#7798bf', '#7cb5ec', '#90ee7e', '#f7a35b', '#f6685d'],
        
    };

    useEffect(() => {
        dispatch(getRatePercent({ company_id: filter.company, country: filter.country, first_date: filter.customDate?.[0] || filter.predefinedDate.dateRange?.first_date, last_date: filter.customDate?.[1] || filter.predefinedDate.dateRange?.last_date }))
    }
    , [filter]);

    return (
        <div className='table_inside_container'>
            {/* <h4 className='tableHeadText'>Rate percent</h4> */}
            <HighchartsReact loading={isLoadingRatePercentData} highcharts={Highcharts} options={options} />
        </div>
    );
};

export default RatePercent;
